import * as $ from 'jquery';
import * as am4core from '@amcharts/amcharts4/core';

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { InteropConfig, windowWithInterop } from './modules/interop/types';

import { AccountingsService } from './services/accountings/accountings.service';
import { ApiService } from './services/api/api.service';
import { CredentialsService } from './services/credentials/credentials.service';
import { CurrenciesService } from './services/currencies/currencies.service';
import { DataService } from './services/data/data.service';
import { ExternalService } from './services/external/external.service';
import { HttpClient } from '../../node_modules/@angular/common/http';
import { HttpUtil } from './modules/utils/classes/HttpUtil.class';
import { ModulesModule } from './modules/modules.module';
import { SettingsComponent } from './components/settings/settings.component';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { config } from './classes/config';
import { environment } from 'src/environments/environment';
import { waitForInterop } from './modules/interop/helpers';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit {
  constructor(private modules: ModulesModule, private http: HttpClient) {}

  @ViewChild('reactRoot') reactRoot: ElementRef;
  @Input('testVal') testProp: any = null;
  public ready = false;

  async ngOnInit() {
    // Init http module
    HttpUtil.init(this.http);

    await ApiService.checkVersionUpdate();
    await DataService.init();
    await ExternalService.init();
    await this.modules.getModules();
    await SettingsComponent.reload();
    await AccountingsService.init();
    await CurrenciesService.init();
    await CredentialsService.init();

    // data grid resize
    window.addEventListener('resize', () => {
      $('data-grid').trigger('resize');
    });

    // Warning on exit
    window.addEventListener('beforeunload', function (e) {
      if (CredentialsService.loggedUser != null) {
        var confirmationMessage = "Attention, vous êtes sur le point de fermer la fenêtre de l'ERP !!";
        e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
      }
    });

    this.ready = await this.initInterop();
  }

  get debugMode() {
    return environment.production === false;
  }

  initInterop = () =>
    waitForInterop().then(() => {
      console.log('interop found, initializing...');
      const interopConfig: InteropConfig = {
        apiUrl: config.apiUrl,
        baseUrl: config.baseUrl,
      };
      windowWithInterop.reactInterop.initInterop(
        this.reactRoot.nativeElement,
        CredentialsService.sessionToken,
        interopConfig
      );
      return true;
    });
}
