import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { Merchant } from 'src/app/modules/other-data/classes/Merchant.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { Wage } from '../../classes/Wage.class';

@Component({
  selector: 'app-wages-view',
  templateUrl: './wages-view.component.html',
  styleUrls: ['./wages-view.component.css'],
})
export class WagesViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Salaires',
    data: this,
    elements: [
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: WagesViewComponent) {
          view.save();
        },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Ajouter<br/>une ligne',
        icon: 'euro-sign',
        click: function (view: WagesViewComponent) {
          view.createWage();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: WagesViewComponent) {
          view.deleteWages();
        },
      },
      { type: 'spacing' },
    ],
  };

  @ViewChild('grid') grid: DataGridComponent = null;

  public wages: Wage[] = [];
  public merchantData: any = { items: [] };

  public wagesGridColumns = [
    {
      title: 'Représentant',
      field: 'merchant',
      type: 'foreign-list',
      multiSelect: false,
      listItems: this.merchantData,
      listField: 'numberAndName',
      nullText: '(Aucun)',
    },
    { title: 'Année', field: 'year', type: 'text' },
    { title: 'Janvier', field: 'amount1', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Février', field: 'amount2', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Mars', field: 'amount3', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Avril', field: 'amount4', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Mais', field: 'amount5', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Juin', field: 'amount6', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Juillet', field: 'amount7', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Aout', field: 'amount8', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Septembre', field: 'amount9', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Octobre', field: 'amount10', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Novembre', field: 'amount11', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'Décembre', field: 'amount12', type: 'number', unit: '€', decimalsCount: 2 },
    { title: 'TOTAL', field: 'total', type: 'number', unit: '€', decimalsCount: 2, readonly: true },
  ];

  constructor(public ref: ChangeDetectorRef, protected elRef: ElementRef) {
    super(ref, elRef);
  }

  onActivate() {
    Wage.load().then(
      (result) => {
        this.wages = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Merchant.load(null, ['number']).then(
      (result) => {
        this.merchantData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  createWage() {
    this.wages.push(new Wage());
  }

  deleteWages() {
    let promises: Promise<any>[] = [];
    for (let i = 0; i < this.grid.selectedItems.length; ++i) {
      promises.push(this.wages[i].moveToTrash());
    }
    if (promises.length > 0) {
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Suppression réussie',
            summary: '<b>' + promises.length + ' salaires</b> ont été supprimés avec succès.',
          });
          ArrayUtil.removeElements(this.wages, this.grid.selectedItems);
          this.grid.selectedItems = [];
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur de suppression',
            summary: "Une erreur s'est produite durant la suppression.",
            content: err,
          });
        }
      );
    }
  }

  save() {
    let promises: Promise<any>[] = [];
    for (let i = 0; i < this.wages.length; ++i) {
      if (this.wages[i].changed) promises.push(this.wages[i].save3(false));
    }
    if (promises.length > 0) {
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Sauvegarde réussie',
            summary: '<b>' + promises.length + ' salaires</b> ont été sauvegardés avec succès.',
          });
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur de sauvegarde',
            summary: "Une erreur s'est produite durant la sauvegarde.",
            content: err,
          });
        }
      );
    }
  }
}
