import { ChangeOrderNumberComponent } from './change-order-number/change-order-number.component';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { DeliveryNotePrintingModel } from './classes/DeliveryNotePrintingModel.class';
import { FormsModule } from '@angular/forms';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { InteropViewComponent } from '../interop/interop-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { Order } from './classes/Order.class';
import { OrderViewComponent } from './views/order-view/order-view.component';
import { OrdersFollowupViewComponent } from './views/orders-followup-view/orders-followup-view.component';
import { OrdersListViewComponent } from './views/orders-list-view/orders-list-view.component';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule],
  declarations: [OrderViewComponent, OrdersListViewComponent, ChangeOrderNumberComponent, OrdersFollowupViewComponent],
  exports: [OrderViewComponent, OrdersListViewComponent, ChangeOrderNumberComponent, OrdersFollowupViewComponent],
  entryComponents: [
    OrderViewComponent,
    OrdersListViewComponent,
    ChangeOrderNumberComponent,
    OrdersFollowupViewComponent,
  ],
})
export class OrdersModule extends ModuleBase {
  public static moduleName = 'orders';

  public ngOnInit() {
    ClassManager.registerClass('Order', Order);

    HomepageViewComponent.registerGroups({
      offers: {
        name: 'Commandes',
        backColor: 'rgb(255, 192, 0)',
        textColor: 'black',
        permission: 'home_groupe_commandes',
        children: [
          {
            permission: 'home_bouton_commandes_choixoffre',
            name: "Choix de l'offre à traiter",
            component: OrderViewComponent,
          },
          ...(config.interop.modules.orders.list
            ? [
                {
                  name: 'Livre des commandes',
                  new: true,
                  permission: 'home_bouton_commandes_livre',
                  href: '/orders',
                },
              ]
            : [
                {
                  permission: 'home_bouton_commandes_livre',
                  name: 'Livre des commandes',
                  component: OrdersListViewComponent,
                },
              ]),
          ...(config.interop.modules.ordersFollowUp.list
            ? [
                {
                  name: 'Suivi des commandes',
                  new: true,
                  permission: 'home_bouton_commandes_livre',
                  href: '/orders_followup',
                },
              ]
            : [
                {
                  permission: 'home_bouton_commandes_suivi',
                  backColor: 'rgb(255, 232, 0)',
                  name: 'Suivi des commandes',
                  component: OrdersFollowupViewComponent,
                },
              ]),
        ],
      },
    });

    return super.ngOnInit();
  }
}
