import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { CustomerPaymentsListViewComponent } from './views/customer-payments-list-view/customer-payments-list-view.component';
import { FormsModule } from '@angular/forms';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { InteropViewComponent } from '../interop/interop-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { Payment } from './classes/Payment.class';
import { PaymentStatus } from './classes/PaymentStatus.class';
import { PrintingModel } from '../printing/classes/PrintingModel.class';
import { PrintingModule } from '../printing/printing.module';
import { ReminderType } from './classes/ReminderType.class';
import { RemindersViewComponentComponent } from './views/reminders-view-component/reminders-view-component.component';
import { SupplierPayment } from './classes/SupplierPayment.class';
import { SupplierPaymentsListViewComponent } from './views/supplier-payments-list-view/supplier-payments-list-view.component';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule, PrintingModule],
  declarations: [CustomerPaymentsListViewComponent, SupplierPaymentsListViewComponent, RemindersViewComponentComponent],
  exports: [CustomerPaymentsListViewComponent, SupplierPaymentsListViewComponent, RemindersViewComponentComponent],
  entryComponents: [
    CustomerPaymentsListViewComponent,
    SupplierPaymentsListViewComponent,
    RemindersViewComponentComponent,
  ],
})
export class PaymentsModule extends ModuleBase {
  public static moduleName = 'payments';

  public ngOnInit() {
    ClassManager.registerClass('PaymentStatus', PaymentStatus);
    ClassManager.registerClass('Payment', Payment);
    ClassManager.registerClass('SupplierPayment', SupplierPayment);
    ClassManager.registerClass('ReminderType', ReminderType);

    HomepageViewComponent.registerGroups({
      payments: {
        name: 'Paiements',
        backColor: 'rgb(127, 127, 127)',
        textColor: 'white',
        permission: 'home_groupe_paiements',
        children: [
          {
            name: 'Paiements des clients',
            permission: 'home_bouton_paiements_clients',
            component: CustomerPaymentsListViewComponent,
          },
          ...(config.interop.modules.payments
            ? [
                {
                  name: 'Paiements des clients',
                  new: true,
                  permission: 'home_bouton_paiements_clients',
                  href: 'customer_payments',
                },
              ]
            : []),
          {
            name: 'Paiements des fournisseurs',
            permission: 'home_bouton_paiements_fournisseurs',
            component: SupplierPaymentsListViewComponent,
          },
          ...(config.interop.modules.payments
            ? [
                {
                  name: 'Paiements des fournisseurs',
                  new: true,
                  permission: 'home_bouton_paiements_clients',
                  href: 'supplier_payments',
                },
              ]
            : []),
        ],
      },
    });

    return super.ngOnInit();
  }
}
