import { BehaviorSubject } from 'rxjs';

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type InteropConfig = {
  apiUrl: string;
  baseUrl: string;
};

type PartialConfig = DeepPartial<InteropConfig>;

interface Path {
  pathname: string;
  search: string;
  hash: string;
}

type To = string | Partial<Path>;
type RelativeRoutingType = 'route' | 'path';

interface NavigateOptions {
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
  flushSync?: boolean;
  viewTransition?: boolean;
}

interface Location<S = any> {
  pathname: string;
  search: string;
  hash: string;
  state: S;
  key: string;
}

interface NavigateFunction {
  (to: To, options?: NavigateOptions): void;
  (delta: number): void;
}

type WindowWithReactInterop = Window & {
  reactInterop: {
    initInterop: (rootElement: Element, sessionToken: string, config?: PartialConfig) => void;
    injectComponent: <K extends string>(key: string, container: Element, name: K, props?: any) => void;
    destroyComponent: (key: string) => void;
    locationChanged$?: BehaviorSubject<Location>;
    navigate: NavigateFunction;
    authenticate: (token?: string) => void;
  };
};

export const windowWithInterop = window as WindowWithReactInterop;
