import * as _ from 'lodash';

import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { RouteDefinition, getRouteDefinitions } from './routeDefinitions';

import { config } from '../../classes/config';
import { waitForInteropNavigate } from '../../modules/interop/helpers';
import { windowWithInterop } from '../../modules/interop/types';
import { LoginViewComponent } from '../../modules/login/views/login-view/login-view.component';
import { chainPromises } from '../../modules/utils/helpers/promises';
import { ExternalService } from '../../services/external/external.service';
import { Toolbar } from '../toolbar/classes/Toolbar.class';
import { AccessViewComponent } from './access-view.component';
import { ViewBaseComponent } from './view-base.component';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss'],
})
export class ViewsComponent implements OnInit {
  public static instance: ViewsComponent;
  public views: AccessViewComponent[] = [];
  public toolbars: Toolbar[] = [];
  public printMode: boolean = false;

  @ViewChild('vcr', { read: ViewContainerRef }) vcr: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private zone: NgZone
  ) {
    ViewsComponent.instance = this;
  }

  get currentPage() {
    return Math.max(this.views.length - 1, 0);
  }

  async ngOnInit() {
    this.openView(LoginViewComponent);
    await waitForInteropNavigate();
    console.log('subscribing to locationChanged');
    windowWithInterop.reactInterop.locationChanged$.subscribe((location) => {
      console.log('received location changed:', location);
      this.zone.run(() => {
        this.updateViewsList(location.pathname);
      });
    });
  }

  findRouteDefinition(route: string, definitions?: RouteDefinition[]) {
    return (definitions || getRouteDefinitions()).find((definition) => {
      return typeof definition.pattern === 'string'
        ? definition.pattern === route
        : definition.pattern.test(route);
    });
  }

  updateViewsList = async (route: string) => {
    const routeSegments = _.trim(route, '/').split('/').filter(Boolean);
    const routeIds = routeSegments.map((segment, index) =>
      routeSegments.slice(0, index + 1).join('/')
    );
    const routeViews = this.views.slice(2);
    let firstDifferentIndex = routeIds.findIndex(
      (routeId, index) =>
        routeViews[index] && routeId !== routeViews[index]._routeId
    );
    if (firstDifferentIndex < 0) {
      firstDifferentIndex = Math.min(routeViews.length, routeIds.length);
    }

    if (routeViews.length !== routeIds.length) {
      // Close views that are not in the new route')
      routeViews.slice(firstDifferentIndex).forEach(() => {
        this.closeView(false);
      });
      // Open views that are in the new route
      const differentRoutes = routeIds.slice(firstDifferentIndex);
      console.log('differentRoutes:', differentRoutes);

      await chainPromises(
        differentRoutes.map((routeId) => async () => {
          const definition = this.findRouteDefinition(routeId);
          const matches = routeId.match(definition.pattern);
          const args =
            typeof definition.viewArgs === 'function'
              ? await definition.viewArgs(matches)
              : definition.viewArgs;
          console.log('view args:', args);
          const view = this.openView(definition.viewComponent, ...args);
          view._routeId = routeId;
        })
      );
    }
  };

  createViewComponent(type) {
    if (!(type.prototype instanceof ViewBaseComponent)) {
      console.log('Bad view type :', type.name);
      return null;
    }
    let factory =
      this.componentFactoryResolver.resolveComponentFactory<AccessViewComponent>(
        type
      );
    let ref: ComponentRef<AccessViewComponent> =
      this.vcr.createComponent<AccessViewComponent>(factory);
    ref.instance._ref = ref;
    return ref ? ref.instance : null;
  }

  static openView(type, ...args) {
    if (this.instance) {
      return ViewsComponent.instance.openView(type, ...args);
    }
  }

  public updateChangeDetection() {
    if (this.views.length > 0) {
      for (let i = 0; i < this.views.length - 1; ++i)
        this.views[i].disableChangeDetection();
      this.views[this.views.length - 1].enableChangeDetection();
    }
  }
  public setWindowTitle(title: string) {
    if (ExternalService.available)
      ExternalService.setWindowTitle(
        title && title.length ? title : `${config.companyName} ERP`
      );
    document.title =
      `${config.companyName} ERP` +
      (title && title.length ? ' - ' + title : '');
  }
  public activateView() {
    if (this.views.length > 0) {
      let view: ViewBaseComponent = this.views[this.views.length - 1];
      view.onActivate();
      this.setWindowTitle(
        view.toolbar && view.toolbar.viewTitle ? view.toolbar.viewTitle : ''
      );
    }
  }

  public openView(type, ...args) {
    let view: AccessViewComponent = this.createViewComponent(
      type
    ) as AccessViewComponent;
    return this.pushView(view, ...args);
  }

  public pushView(view: AccessViewComponent, ...args) {
    if (view) {
      this.toolbars.push(view.toolbar);
      this.views.push(view);
      view.viewLevel = this.views.length + 1;
      view.views = this;
      view.initView(...args);
      view.onActivate();
      this.setWindowTitle(
        view.toolbar && view.toolbar.viewTitle ? view.toolbar.viewTitle : ''
      );
      this.updateChangeDetection();
    }
    return view;
  }

  public get currentView() {
    if (!this.views || this.views.length == 0) return null;
    else return this.views[this.views.length - 1];
  }

  closeView(navigate = true) {
    console.log(
      'closeView for current views:',
      this.views.map((view) => view.constructor.name)
    );
    let view: AccessViewComponent = this.views.pop();
    this.vcr.remove(this.vcr.length - 1);
    this.toolbars.pop();
    view._ref.destroy();
    this.updateChangeDetection();
    this.activateView();
    if (navigate) {
      windowWithInterop.reactInterop.navigate(
        '/' + this.views[this.views.length - 1]._routeId
      );
    }
  }

  static closeView() {
    return ViewsComponent.instance.closeView();
  }

  public static closeAllViews() {
    while (ViewsComponent.instance.views.length > 0)
      ViewsComponent.instance.closeView();
  }
}
