import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ApiService } from '../../../../services/api/api.service';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { DataGridRowAction } from '../../../../components/data-grid/classes/DataGridRowAction.class';
import { DateTimeUtil } from '../../../utils/classes/DateTimeUtil.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { Order } from '../../classes/Order.class';
import { OrderViewComponent } from '../order-view/order-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
import { createOrder } from '../../helpers';

// import { OrderCreateViewComponent } from '../order-create-view/order-create-view.component';

@Component({
  selector: 'app-orders-list-view',
  templateUrl: './orders-list-view.component.html',
  styleUrls: ['./orders-list-view.component.css'],
})
export class OrdersListViewComponent extends AccessViewComponent {
  public permission = 'ecriture_commandes_livrecommandes';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Bons de commande',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Créer une<br/>commande',
        icon: 'euro-sign',
        click: function (view: OrdersListViewComponent) {
          view.createNewOrder();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: OrdersListViewComponent) {
          view.deleteOrders();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: OrdersListViewComponent) {
          view.archiveOrders();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Désarchiver',
        icon: 'archive',
        click: function (view: OrdersListViewComponent) {
          view.archiveOrders(false);
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: OrdersListViewComponent) {
          view.filterOrders = !this.value;
          view.reloadOrders();
          //view.updateSuppliersList();
        },
      },
      { type: 'spacing' },
    ],
  };

  public filterOrders = true;
  public orders: Order[] = [];
  public ordersGridColumns = [
    {
      title: 'Numéro de\ncommande',
      field: 'number',
      textColor: (item: Order) => {
        return;
      },
      width: 200,
    },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Date', type: 'date', field: 'date', width: 80 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 150 },
    { title: 'Désignation', field: 'article.designation', width: 500 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    {
      title: "Date de l'offre\nfournisseur",
      type: 'date',
      field: 'supplier_date',
      width: 80,
    },
    {
      title: "N° de l'offre\nfournisseur",
      field: 'supplier_reference',
      width: 150,
    },
    {
      title: 'N° ou type\nde commande\nclient',
      field: 'customer_reference',
      width: 150,
    },
    { title: 'N° de dossier\ncommercial', field: 'sale.number', width: 200 },
    { title: 'Représentant', field: 'merchant.numberAndName', width: 150 },
    // { title: 'Livraisons', field: 'deliveries' },
    // { title: 'Stockage', field: 'storage' },
    {
      title: "Prix\nd'achat\nà l'unité\nH.T.V.A.",
      field: 'converted_unitBuyPrice',
      type: 'number',
      decimalsCount: 5,
      currencyField: 'buy_currency',
      width: 130,
    },
    {
      title: 'Frais de\ngestion',
      field: 'converted_internal_fees',
      type: 'number',
      decimalsCount: 2,
      currencyField: 'sell_currency',
      width: 130,
    },
    {
      title: "Prix de\nvente\nà l'unité\nH.T.V.A.",
      field: 'converted_sell_price',
      type: 'number',
      decimalsCount: 5,
      currencyField: 'sell_currency',
      width: 130,
    },
    { title: 'Nb. de\nfactu-\nrations', field: 'invoicings', width: 80 },
    {
      title: 'Montant\ntotal\ncommande\nH.T.V.A.',
      field: 'converted_totalAmount',
      type: 'number',
      decimalsCount: 2,
      currencyField: '  ',
      headerBackColor: 'rgb(96, 73, 122)',
      headerTextColor: 'white',
      backColor: 'rgb(204, 192, 218)',
      width: 130,
    },
    {
      title: 'Montant\nacheté\nH.T.V.A.',
      field: 'converted_total_buyPrice',
      type: 'number',
      decimalsCount: 2,
      currencyField: 'buy_currency',
      headerBackColor: 'rgb(226, 107, 10)',
      headerTextColor: 'white',
      backColor: 'rgb(252, 213, 180)',
      width: 130,
    },
    {
      title: 'Montant de\nla marge\nbrute H.T.V.A.',
      field: 'rawMargin',
      type: 'number',
      decimalsCount: 2,
      unit: '€',
      headerBackColor: 'rgb(153, 0, 0)',
      headerTextColor: 'white',
      backColor: 'rgb(192, 0, 0)',
      textColor: 'white',
      width: 130,
    },
    {
      title: 'Marge\nbrute',
      field: 'rawMarginPercentage',
      type: 'number',
      decimalsCount: 2,
      unit: '%',
      headerBackColor: 'rgb(153, 0, 0)',
      headerTextColor: 'white',
      backColor: 'rgb(192, 0, 0)',
      textColor: 'white',
      width: 130,
    },
    {
      title: 'Montant de\nla marge\nnette H.T.V.A.',
      field: 'netMargin',
      type: 'number',
      decimalsCount: 2,
      unit: '€',
      headerBackColor: 'rgb(255,192,0)',
      backColor: 'rgb(255,255,153)',
      width: 130,
    },
    {
      title: 'Marge\nnette',
      field: 'netMarginPercentage',
      type: 'number',
      decimalsCount: 2,
      unit: '%',
      headerBackColor: 'rgb(255,192,0)',
      backColor: 'rgb(255,255,153)',
      width: 130,
    },
    {
      title: 'N° de facture',
      field: 'invoices_list',
      width: 200,
    },
    { title: 'Remarques internes', field: 'internal_remarks', width: 500 },
  ];

  @ViewChild('grid') grid: DataGridComponent;
  public gridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.orderDblClick(event, item);
      },
    },
  ];

  ngOnInit() {
    this.reloadOrders();
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadOrders();
  }

  public reloadOrders() {
    const self = this;
    const conditions = [];
    if (AccountingsService.currentAccounting)
      conditions.push(`id_accounting='${AccountingsService.currentAccounting.id}'`);
    if (this.filterOrders) conditions.push('archived = 0');
    Order.load(null, null, null, false, conditions.length > 0 ? conditions.join(' AND ') : null, true).then(
      function (result) {
        self.orders = result;
        self.orders.sort((a: Order, b: Order) => {
          if ((b.date >= '2019-01-01' && a.date >= '2019-01-01') || (b.date < '2019-01-01' && a.date < '2019-01-01')) {
            return b.number.localeCompare(a.number);
          } else if (b.date >= '2019-01-01') {
            return 1;
          } else if (a.date >= '2019-01-01') {
            return -1;
          }
        });
      },
      function (err) {
        console.error(err);
      }
    );
  }

  //
  // public get filteredOrders(): Order[] {
  //     if (!this.filterOrders) { return this.orders; } else { return this.orders.filter((value: Order, index: number, array: Order[]) => !value.archived || value.archived != 1); }
  // }

  createNewOrder() {
    const order = createOrder();
    this.orders.push(order);
    ViewsComponent.openView(OrderViewComponent, order);
  }

  deleteOrders() {
    const orders: Order[] = this.grid.selectedItems as Order[];
    if (Array.isArray(orders) && orders.length > 0) {
      // for (let i = 0; i < orders.length; ++i) {
      //     if (orders[i].invoices != null && orders[i].invoices.length > 0) {
      //         DialogsComponent.display({
      //             title: 'Suppression impossible',
      //             icon: 'warning',
      //             message: 'Impossible de supprimer la commande n°<b>' + orders[i].number + '</b>.<br/>Une ou plusieurs factures sont liées à cette commande.',
      //             buttons: DialogButton.okOnlyButtons
      //         });
      //         return;
      //     }
      // }
      const promises: any[] = [];
      for (let i = 0; i < orders.length; ++i) {
        promises.push(orders[i].moveToTrash());
      }
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Commandes supprimées',
            summary: 'Les commandes ont été supprimées avec succès',
          });
          ArrayUtil.removeElements(this.orders, orders);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur lors de la suppression',
            summary: "Une erreur s'est produite lors de la suppression",
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  archiveOrders(archive = true) {
    const orders: Order[] = this.grid.selectedItems as Order[];
    if (Array.isArray(orders) && orders.length > 0) {
      const ids: string[] = [];
      for (let i = 0; i < orders.length; ++i) {
        // orders[i].archived = (archive === false ? 0 : 1);
        ids.push(orders[i].id);
      }
      ApiService.callModule('orders', 'archive', {
        ids: ids,
        archive: archive ? 1 : 0,
      }).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Commandes archivées',
            summary: 'Les commandes ont été ' + (archive === false ? 'dés' : '') + 'archivées avec succès',
          });
          this.grid.clearSelection();
          this.reloadOrders();
          // ArrayUtil.removeElements(this.orders, orders);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: "Erreur lors de l'archivage",
            summary:
              "Une erreur s'est produite lors " + (archive === false ? 'du dés' : "de l'") + 'archivage des commandes',
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  orderDblClick(event, order) {
    ViewsComponent.openView(OrderViewComponent, order);
  }

  gridRowStyle(item: Order) {
    return item && item.archived == 1
      ? {
          color: 'gray',
          'font-style': 'italic',
        }
      : null;
  }
}
