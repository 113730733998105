import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { DataGridRowAction } from 'src/app/components/data-grid/classes/DataGridRowAction.class';
import { DataListComponent } from '../../../../components/data-list/data-list.component';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { Supplier } from '../../classes/Supplier.class';
import { SupplierViewComponent } from '../supplier-view/supplier-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
import { createSupplierWithNewNumber } from '../../helpers';

@Component({
  selector: 'app-suppliers-list-view',
  templateUrl: './suppliers-list-view.component.html',
  styleUrls: ['./suppliers-list-view.component.css'],
})
export class SuppliersListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_basededonnees_liste_fournisseurs';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Liste des fournisseurs',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        text: 'Créer un<br/>fournisseur',
        icon: 'user-plus',
        click: function (view: SuppliersListViewComponent) {
          view.createSupplier();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        name: 'refreshList',
        type: 'button',
        text: 'Rafraîchir<br/>la liste',
        icon: 'sync-alt',
        click: function (view: SuppliersListViewComponent) {
          view.refreshList();
        },
        disabled: true,
      },
      { type: 'separator-large' },
      {
        name: 'deleteSuppliers',
        type: 'button',
        text: 'Supprimer le(s)<br/>fournisseur(s)',
        icon: 'user-minus',
        click: function (view: SuppliersListViewComponent) {
          view.deleteSuppliers();
        },
        disabled: true,
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: SuppliersListViewComponent) {
          view.archiveSuppliers();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Désarchiver',
        icon: 'archive',
        click: function (view: SuppliersListViewComponent) {
          view.archiveSuppliers(false);
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: SuppliersListViewComponent) {
          view.showArchived = this.value;
          view.refreshList();
          // if (view.filterOrders) view.grid.clearSelection();
        },
      },
      { type: 'spacing' },
    ],
  };

  get tbViewType() {
    return Toolbar.getToolbarItem(this.toolbar, 'viewTypeSelect').value;
  }

  items = null;
  gridColumns = [
    { title: 'Nom', field: 'name', width: 250, fontSize: '14pt', fontWeight: 'bold' },
    { title: 'Numéro', field: 'number', width: 150, fontSize: '14pt', fontWeight: 'bold' },
    { title: 'Catégorie', field: 'category.name', width: 120 },
    { title: 'Langue', field: 'lang.name', width: 90 },
    { title: 'Paiement', field: 'deadline', width: 150 },
    { title: 'E-mail', field: 'commercial_address.email', type: 'email', width: 250 },
    { title: 'N° TVA', field: 'vat_number', width: 120 },
    { title: 'Code\npostal', field: 'commercial_address.postcode', width: 80 },
    { title: 'Localité', field: 'commercial_address.city', width: 150 },
    { title: 'Pays', field: 'commercial_address.country.name', width: 150 },
    { title: 'Remarques', field: 'remarks', width: 300 },
    // { title: 'Représentant', field: 'merchant.name', width: 180 }
  ];
  listFields = [
    {
      style: {},
      fields: [
        {
          title: 'Badge',
          field: 'name',
          name: 'badge',
          function: (value, field) => {
            return SuppliersListViewComponent.getInitials(value);
          },
          template: '<div class="initials-badge">##value##</div>',
        },
      ],
    },
    {
      style: { 'min-width': '350px' },
      fields: [
        { title: 'Nom', field: 'name', name: 'name' },
        { title: 'Numéro', field: 'number', name: 'number', template: 'Numéro : <b>##value##</b>' },
      ],
    },
    {
      style: { flex: '1' },
      fields: [
        { title: 'Produits', field: 'product', name: 'product', template: 'Produits : ##value##' },
        { title: 'Remarques', field: 'remarks', name: 'remarks', template: 'Remarques : ##value##' },
      ],
    },
  ];
  public listActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.itemDblClick(event, item);
      },
    },
  ];

  public showArchived = false;

  @ViewChild('grid') grid: DataGridComponent;

  ngOnInit() {
    this.refreshList();
  }

  refreshList() {
    const conditions: string[] = [];
    if (!this.showArchived) conditions.push('archived != 1');
    Supplier.load(null, ['name'], null, false, conditions.join(' AND ') || null).then(
      (suppliers: Supplier[]) => {
        this.items = suppliers.sort((a: Supplier, b: Supplier) => {
          return a.name.localeCompare(b.name);
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  updateSelectedCustomers() {
    Toolbar.getToolbarItem(this.toolbar, 'deleteSuppliers').disabled = this.grid.selectedItems.length == 0;
  }

  deleteSuppliers() {
    let self = this;
    for (let i = 0; i < this.grid.selectedItems.length; ++i) {
      this.grid.selectedItems[i].delete().then(
        function (result) {
          ArrayUtil.removeElements(self.items, [self.grid.selectedItems[i]]);
        },
        function (err) {
          console.error('error while deleting supplier:', err);
        }
      );
    }
    this.refreshList();
  }

  async createSupplier() {
    const supplier = await createSupplierWithNewNumber();
    this.items.push(supplier);
    this.itemDblClick(null, supplier);
  }

  itemClick(item: Supplier) {
    this.updateSelectedCustomers();
  }

  itemDblClick(event, item) {
    this.updateSelectedCustomers();
    console.log('item=', item, 'event:', event);
    ViewsComponent.openView(SupplierViewComponent, item);
  }

  static getInitials(value) {
    let initials = value.match(/\b[A-Z0-9]/g) || [];
    return initials.join('').substring(0, 3);
  }

  archiveSuppliers(archive = true) {
    let suppliers: Supplier[] = this.grid.selectedItems as Supplier[];
    if (Array.isArray(suppliers) && suppliers.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < suppliers.length; ++i) {
        suppliers[i].archived = archive === false ? 0 : 1;
        promises.push(suppliers[i].save2());
      }
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Fournisseurs archivés',
            summary: 'Les fournisseurs ont été ' + (archive === false ? 'dés' : '') + 'archivés avec succès',
          });
          this.grid.clearSelection();
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: "Erreur lors de l'archivage",
            summary:
              "Une erreur s'est produite lors " +
              (archive === false ? 'du dés' : "de l'") +
              'archivage des fournisseurs',
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  gridRowStyle(item: Supplier, selectColumn: boolean = false) {
    if (!item || !item.archived) return null;
    return {
      color: 'gray',
      'font-style': 'italic',
    };
  }
}
