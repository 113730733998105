import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { Charge } from '../../classes/Charge.class';
import { ChargeType } from '../../classes/ChargeType.class';
import { ChargeViewComponent } from '../charge-view/charge-view.component';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { NumberUtil } from 'src/app/modules/utils/classes/NumberUtil.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Component({
  selector: 'app-charges-view',
  templateUrl: './charges-list-view.component.html',
  styleUrls: ['./charges-list-view.component.css'],
})
export class ChargesListViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Liste des charges',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Encoder<br/>une charge',
        icon: 'euro-sign',
        click: function (view: ChargesListViewComponent) {
          view.createCharge();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: ChargesListViewComponent) {
          view.deleteCharges();
        },
      },
      { type: 'spacing' },
    ],
  };

  public chargeTypes: ChargeType[] = [];
  public chargesData: any = { items: [] };
  @ViewChild('grid') grid: DataGridComponent = null;

  public additionalHeadersHtml: any[] = [];
  public fixedAdditionalHeadersHtml: any[] = [];
  public copyAdditionalHeadersHtml: any[] = [];
  public headerColumns: any[] = [];
  public bodyColumns: any[] = [];

  constructor(private sanitizer: DomSanitizer, public ref: ChangeDetectorRef, protected elRef: ElementRef) {
    super(ref, elRef);
  }

  ngOnInit() {
    // ChargeType.load().then(
    //     (result1) => {
    //         this.updateColumns(result1);
    //         Charge.load().then(
    //             (result) => {
    //                 this.updateProperties(result);
    //                 this.updateColumns(result1, result);
    //                 this.chargesData.items = result;
    //             },
    //             (err) => { console.error(err); }
    //         );
    //     },
    //     (err) => { console.error(err); }
    // );
  }

  onActivate() {
    this.reloadCharges();
  }
  onAccountingChanged(accounting: Accounting) {
    this.reloadCharges();
  }

  reloadCharges() {
    let conditions: string = null;
    if (AccountingsService.currentAccounting) conditions = `id_accounting='${AccountingsService.currentAccounting.id}'`;
    ChargeType.load().then(
      (result1) => {
        this.chargeTypes = result1;
        this.updateColumns(result1);
        Charge.load(null, null, null, false, conditions).then(
          (result) => {
            this.updateProperties(result);
            this.updateColumns(result1, result);
            this.chargesData.items = result;
          },
          (err) => {
            console.error(err);
          }
        );
      },
      (err) => {
        console.error(err);
      }
    );
  }

  updateColumns(types: ChargeType[], charges: Charge[] = null) {
    let dateCol = { title: "Date de\nl'OP", field: 'date', type: 'date', width: 80 };
    let dateeffCol = { title: 'Date effective', field: 'effective_date', type: 'date', width: 80 };
    let numCol = { title: "N° de\nl'OP", field: 'number', width: 70 };
    let nameCol = { title: 'Bénéficiaire', field: 'name', width: 200 };
    let merchantCol = { title: 'Représentant à qui imputer la charge', field: 'merchant.numberAndName', width: 150 };
    let remarksCol = { title: 'Remarques', field: 'remarks', width: 250 };
    this.headerColumns = [dateCol, dateeffCol, numCol, nameCol, merchantCol, remarksCol];
    this.bodyColumns = [dateCol, dateeffCol, numCol, nameCol, merchantCol, remarksCol];
    // this.headerColumns = [
    //     { title: 'Date de\nl\'OP', field: 'date', type: 'date', width: 80 },
    //     { title: 'N° de\nl\'OP', field: 'number', width: 70 },
    //     { title: 'Bénéficiaire', field: 'name', width: 200 },
    //     { title: 'Représentant à qui imputer la charge', field: 'merchant.name', width: 150 },
    //     { title: 'Remarques', field: 'remarks', width: 250 }
    // ];
    // this.bodyColumns = [
    //     { title: 'Date de\nl\'OP', field: 'date', type: 'date', width: 80 },
    //     { title: 'N° de\nl\'OP', field: 'number', width: 70 },
    //     { title: 'Bénéficiaire', field: 'name', width: 200 },
    //     { title: 'Représentant à qui imputer la charge', field: 'merchant.name', width: 150 },
    //     { title: 'Remarques', field: 'remarks', width: 250 }
    // ];
    let fixed_html1: string =
      '<td class="selection-header-cell">&nbsp;<br/>&nbsp;</td>' +
      '<td class="additional-header-cell" style="width:80px"></td>' +
      '<td class="additional-header-cell" style="width:80px"></td>' +
      '<td class="additional-header-cell" style="width:70px"></td>' +
      '<td class="additional-header-cell" style="width:200px"></td>' +
      '<td class="additional-header-cell" style="width:150px"></td>';
    let html1: string = '<td class="additional-header-cell" style="width:250px"></td>';
    let fixed_html2: string =
      '<td class="selection-header-cell">&nbsp;</td>' +
      '<td class="additional-header-cell" style="width:80px"></td>' +
      '<td class="additional-header-cell" style="width:80px"></td>' +
      '<td class="additional-header-cell" style="width:70px"></td>' +
      '<td class="additional-header-cell" style="width:200px"></td>' +
      '<td class="additional-header-cell" style="width:150px"></td>';
    let html2: string = '<td class="additional-header-cell" style="width:250px"></td>';
    let copy_html1: string = fixed_html1 + html1;
    let copy_html2: string = fixed_html2 + html2;
    for (let i = 0; i < types.length; ++i) {
      html1 +=
        '<td class="additional-header-cell additional-header-cell-title" style="max-width:120px">Montant de la<br/>charge</td>';
      html1 +=
        '<td class="additional-header-cell additional-header-cell-title" style="max-width:120px">TVA à récupérer<br/>' +
        (types[i].default_vat ? '(' + types[i].default_vat + ' %)' : '') +
        '</td>';
      copy_html1 +=
        '<td class="additional-header-cell additional-header-cell-title" style="max-width:120px">Montant de la charge</td>';
      copy_html1 +=
        '<td class="additional-header-cell additional-header-cell-title" style="max-width:120px">TVA à récupérer ' +
        (types[i].default_vat ? '(' + types[i].default_vat + ' %)' : '') +
        '</td>';
      let total_amount: number = 0;
      let total_vat: number = 0;
      if (Array.isArray(charges))
        charges.forEach((charge: Charge, index: number, arr: Charge[]) => {
          if (charge.type.id == types[i].id) {
            total_amount += charge.converted_amount;
            total_vat += charge.converted_vat;
          }
        });
      html2 +=
        '<td class="additional-header-cell additional-header-cell-amount total_amount" style="max-width:120px">' +
        (charges && total_amount > 0
          ? NumberUtil.formatMoney(total_amount, AccountingsService.currentAccounting.default_currency.symbol, 2, '.')
          : '') +
        '</td>';
      html2 +=
        '<td class="additional-header-cell additional-header-cell-amount total_vat" style="max-width:120px">' +
        (charges && total_vat > 0
          ? NumberUtil.formatMoney(total_vat, AccountingsService.currentAccounting.default_currency.symbol, 2, '.')
          : '') +
        '</td>';
      copy_html2 +=
        '<td class="additional-header-cell additional-header-cell-amount total_amount" style="max-width:120px">' +
        (charges && total_amount > 0 ? Math.round(total_amount * 100) / 100 : '') +
        '</td>';
      copy_html2 +=
        '<td class="additional-header-cell additional-header-cell-amount total_vat" style="max-width:120px">' +
        (charges && total_vat > 0 ? Math.round(total_vat * 100) / 100 : '') +
        '</td>';
      this.headerColumns.push({
        title: types[i].name,
        colspan: 2,
        field: 'type_' + i,
        width: 241,
      });
      this.bodyColumns.push({
        field: 'converted_amount_' + types[i].id,
        type: 'number',
        decimalsCount: 2,
        currencyField: 'currency',
        textAlign: 'right',
        width: 120,
      });
      this.bodyColumns.push({
        field: 'converted_vat_' + types[i].id,
        type: 'number',
        decimalsCount: 2,
        currencyField: 'currency',
        textAlign: 'right',
        width: 120,
      });
    }
    html1 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-title">Montant de la charge</td>';
    html1 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-title">TVA à récupérer</td>';
    html1 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-title">Montant total</td>';
    copy_html1 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-title">Montant de la charge</td>';
    copy_html1 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-title">TVA à récupérer</td>';
    copy_html1 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-title">Montant total</td>';
    let global_amount: number = 0;
    let global_vat: number = 0;
    if (Array.isArray(charges))
      charges.forEach((charge: Charge, index: number, arr: Charge[]) => {
        global_amount += charge.converted_amount;
        global_vat += charge.converted_vat;
      });
    html2 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-amount">' +
      (charges && global_amount > 0 ? NumberUtil.formatMoney(global_amount, '€', 2, '.') : '') +
      '</td>';
    html2 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-amount">' +
      (charges && global_vat > 0 ? NumberUtil.formatMoney(global_vat, '€', 2, '.') : '') +
      '</td>';
    html2 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-amount">' +
      (charges && global_amount > 0 && global_vat > 0
        ? NumberUtil.formatMoney(global_amount + global_vat, '€', 2, '.')
        : '') +
      '</td>';
    copy_html2 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-amount">' +
      (charges && global_amount > 0 ? global_amount : '') +
      '</td>';
    copy_html2 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-amount">' +
      (charges && global_vat > 0 ? global_vat : '') +
      '</td>';
    copy_html2 +=
      '<td class="additional-header-cell additional-header-cell-total additional-header-cell-amount">' +
      (charges && global_amount > 0 && global_vat > 0 ? global_amount + global_vat : '') +
      '</td>';
    this.headerColumns.push({
      title: 'CHARGES TOTALES',
      backColor: 'rgb(49,134,155)',
      textColor: 'white',
      colspan: 3,
      field: 'total_charges',
      width: 363,
    });
    this.bodyColumns.push({
      field: 'converted_amount',
      type: 'number',
      decimalsCount: 2,
      currencyField: 'currency',
      textAlign: 'right',
      backColor: 'rgb(153,204,255)',
      textColor: 'black',
      width: 120,
    });
    this.bodyColumns.push({
      field: 'converted_vat',
      type: 'number',
      decimalsCount: 2,
      currencyField: 'currency',
      textAlign: 'right',
      backColor: 'rgb(153,204,255)',
      textColor: 'black',
      width: 120,
    });
    this.bodyColumns.push({
      field: 'converted_total',
      type: 'number',
      decimalsCount: 2,
      currencyField: 'currency',
      textAlign: 'right',
      backColor: 'rgb(153,204,255)',
      textColor: 'black',
      width: 120,
    });
    this.additionalHeadersHtml[0] = this.sanitizer.bypassSecurityTrustHtml(html1);
    this.additionalHeadersHtml[1] = this.sanitizer.bypassSecurityTrustHtml(html2);
    this.fixedAdditionalHeadersHtml[0] = this.sanitizer.bypassSecurityTrustHtml(fixed_html1);
    this.fixedAdditionalHeadersHtml[1] = this.sanitizer.bypassSecurityTrustHtml(fixed_html2);
    this.copyAdditionalHeadersHtml[0] = this.sanitizer.bypassSecurityTrustHtml(copy_html1);
    this.copyAdditionalHeadersHtml[1] = this.sanitizer.bypassSecurityTrustHtml(copy_html2);
  }

  updateProperties(charges: Charge[]) {
    for (let i = 0; i < charges.length; ++i) {
      let props = Object.getOwnPropertyNames(charges[i]);
      for (let j = 0; j < props.length; ++j) {
        if (props[j].startsWith('amout_') || props[j].startsWith('vat_')) delete charges[i][props[j]];
      }
      Object.defineProperty(charges[i], 'converted_amount_' + charges[i].type.id, {
        get: () => {
          return charges[i].converted_amount;
        },
        set: (value: number) => {
          charges[i].converted_amount = value;
        },
      });
      charges[i]['converted_vat_' + charges[i].type.id] = charges[i].converted_vat;
      // console.log(charges[i]);
    }
  }

  createCharge() {
    let charge: Charge = new Charge();
    charge.accounting = AccountingsService.currentAccounting;
    charge.currency = charge.accounting.default_currency;
    charge.date = DateTimeUtil.toDateString(new Date());
    charge.effective_date = charge.date;
    this.chargesData.items.push(charge);
    ViewsComponent.openView(ChargeViewComponent, charge);
  }

  deleteCharges() {
    let charges: Charge[] = this.grid.selectedItems as Charge[];
    if (Array.isArray(charges) && charges.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < charges.length; ++i) promises.push(charges[i].delete());
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Charges supprimées',
            summary: 'Les charges ont été supprimés avec succès',
          });
          ArrayUtil.removeElements(this.chargesData.items, charges);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur lors de la suppression',
            summary: "Une erreur s'est produite lors de la suppression",
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  chargeDblClick(event, charge) {
    ViewsComponent.openView(ChargeViewComponent, charge);
  }

  onFiltersChanged() {
    this.updateColumns(this.chargeTypes, this.grid.filteredItems);
  }
}
