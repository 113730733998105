import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { DataGridRowAction } from 'src/app/components/data-grid/classes/DataGridRowAction.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { Sale } from '../../classes/Sale.class';
import { SaleViewComponent } from '../sale-view/sale-view.component';
import { SqlDataGridComponent } from '../../../../components/sql-data-grid/sql-data-grid.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';

@Component({
  selector: 'app-sales-sql-list-view',
  templateUrl: './sales-sql-list-view.component.html',
  styleUrls: ['./sales-sql-list-view.component.css'],
})
export class SalesSqlListViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Dossiers clients',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Créer un<br/>dossier',
        icon: 'euro-sign',
        click: function (view: SalesSqlListViewComponent) {
          view.createSale();
        },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: SalesSqlListViewComponent) {
          view.deleteSales();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: SalesSqlListViewComponent) {
          view.archiveSales();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: SalesSqlListViewComponent) {
          view.filterSales = !this.value;
          if (view.filterSales) view.grid.clearSelection();
          //view.updateSuppliersList();
        },
      },
      { type: 'spacing' },
    ],
  };

  public filterSales: boolean = true;
  public sales: Sale[] = [];
  public salesGridColumns = [
    { title: 'Date', type: 'date', field: 'date', width: 90 },
    { title: 'Numéro', field: 'number', width: 200 },
    { title: 'Représentant', field: 'merchant', width: 130 },
    { title: 'Client', field: 'customer', width: 200 },
    { title: 'N° de commande client', field: 'customer_reference', width: 200 },
    { title: 'Désignation', field: 'article', width: 600 },
    { title: 'Nomenclature', field: 'nomenclature', width: 300 },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Format</span>\n<span style="color:rgb(0,164,48);">Marque et modèle</span>\n<span style="color:rgb(214, 188, 0);">Description</span>'
      ),
      field: 'field1',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Matière</span>\n<span style="color:rgb(0,164,48);">Technologie</span>'
      ),
      field: 'field2',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Matière (clients)</span>\n<span style="color:rgb(0,164,48);">Résolution</span>'
      ),
      field: 'field3',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Impression</span>\n<span style="color:rgb(0,164,48);">Vitesse max.</span>'
      ),
      field: 'field4',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">ø du mandrin / rouleau</span>\n<span style="color:rgb(0,164,48);">Largeur du média</span>'
      ),
      field: 'field5',
      width: 170,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Présentation</span>\n<span style="color:rgb(0,164,48);">Epaisseur du média</span>'
      ),
      field: 'field6',
      width: 120,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Nb. d\'éti./feuil./bob./parav.</span>\n<span style="color:rgb(0,164,48);">Mémoire</span>'
      ),
      field: 'field7',
      width: 120,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Esp. vertical/horizontal</span>\n<span style="color:rgb(0,164,48);">Couteau</span>'
      ),
      field: 'field8',
      width: 100,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Sens d\'enroulement</span>\n<span style="color:rgb(0,164,48);">Interface (standard)</span>'
      ),
      field: 'field9',
      width: 150,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Laize</span>\n<span style="color:rgb(0,164,48);">Programmation</span>'
      ),
      field: 'field10',
      width: 90,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Pinfeed</span>\n<span style="color:rgb(0,164,48);">Options</span>'
      ),
      field: 'field11',
      width: 100,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Perforations / Façonnage</span>\n<span style="color:rgb(0,164,48);">Construction</span>'
      ),
      field: 'field12',
      width: 200,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Autres</span>\n<span style="color:rgb(0,164,48);">Garantie</span>'
      ),
      field: 'field13',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Conditionnement</span>\n<span style="color:rgb(0,164,48);">Autres</span>'
      ),
      field: 'field14',
      width: 250,
    },
    { title: this.sr.bypassSecurityTrustHtml('Facturations'), field: 'invoicings', width: 100 },
    { title: this.sr.bypassSecurityTrustHtml('Livraisons'), field: 'deliveries', width: 100 },
    { title: this.sr.bypassSecurityTrustHtml('Stockage'), field: 'storage', width: 100 },
  ];
  public salesGridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.saleDblClick(event, item);
      },
    },
  ];

  @ViewChild('grid') grid: SqlDataGridComponent;

  public constructor(private sr: DomSanitizer, protected ref: ChangeDetectorRef, protected elRef: ElementRef) {
    super(ref, elRef);
  }

  ngOnInit() {
    this.reloadSales();
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadSales();
  }

  public reloadSales() {
    let conditions: string[] = [];
    // current accounting condition
    if (AccountingsService.currentAccounting)
      conditions.push(`id_accounting='${AccountingsService.currentAccounting.id}'`);
    // see all merchants condition
    if (!CredentialsService.isUserAllowed('dossier_commerciaux_voir_tous_representants'))
      conditions.push(`id_merchant='${CredentialsService.loggedMerchant.id}'`);
    Sale.load(null, ['~date'], null, false, conditions.join(' AND ')).then(
      (result) => {
        this.sales = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public get filteredSales(): Sale[] {
    if (!this.filterSales) return this.sales;
    else
      return this.sales.filter((value: Sale, index: number, array: Sale[]) => !value.archived || value.archived != 1);
  }

  createSale() {
    // let sale: Sale = new Sale();
    // sale.date = DateTimeUtil.toDateString(new Date());
    // this.sales.push(sale);
    ViewsComponent.openView(SaleViewComponent, null);
  }

  deleteSales() {
    DialogsComponent.display({
      icon: 'warning',
      title: 'Supprimer les dossiers ?',
      message: 'Êtes-vous sûr(e) de vouloir supprimer les dossiers sélectionnés ?',
      buttons: DialogButton.yesNoButtons,
    }).then((result: any) => {
      if (result === DialogButton.RESULT_YES) {
        let sales: Sale[] = this.grid.selectedItems as Sale[];
        if (Array.isArray(sales) && sales.length > 0) {
          let promises: any[] = [];
          for (let i = 0; i < sales.length; ++i) promises.push(sales[i].moveToTrash());
          Promise.all(promises).then(
            (result) => {
              NotificationsComponent.push({
                type: 'success',
                title: 'Dossiers clients supprimés',
                summary: 'Les dossiers clients ont été supprimées avec succès',
              });
              ArrayUtil.removeElements(this.sales, sales);
            },
            (err) => {
              NotificationsComponent.push({
                type: 'error',
                title: 'Erreur lors de la suppression',
                summary: "Une erreur s'est produite lors de la suppression",
                content: err,
              });
              console.error(err);
            }
          );
        }
      }
    });
  }

  archiveSales() {
    let sales: Sale[] = this.grid.selectedItems as Sale[];
    if (Array.isArray(sales) && sales.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < sales.length; ++i) {
        sales[i].archived = 1;
        promises.push(sales[i].save2());
      }
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Commandes archivées',
            summary: 'Les commandes ont été archivées avec succès',
          });
          this.grid.clearSelection();
          // ArrayUtil.removeElements(this.sales, sales);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: "Erreur lors de l'archivage",
            summary: "Une erreur s'est produite lors de l'archivage des commandes",
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  saleDblClick(event, sale) {
    ViewsComponent.openView(SaleViewComponent, sale);
  }

  gridRowStyle(item: Sale) {
    return item && item.archived == 1
      ? {
          color: 'gray',
          'font-style': 'italic',
        }
      : null;
  }
}
