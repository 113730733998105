import { windowWithInterop } from './types';

export const waitForInterop = () =>
  new Promise<boolean>((resolve) => {
    var interval = setInterval(() => {
      if (windowWithInterop.reactInterop) {
        clearInterval(interval);
        console.log('interop found');

        resolve(true);
      } else {
        console.log('interop not found, waiting...');
      }
    }, 500);
  });

export const waitForInteropNavigate = () =>
  new Promise<boolean>((resolve) => {
    var interval = setInterval(() => {
      if (windowWithInterop.reactInterop && typeof windowWithInterop.reactInterop.navigate === 'function') {
        clearInterval(interval);
        console.log('interop navigate found');

        resolve(true);
      } else {
        console.log('interop navigate not found, waiting...');
      }
    }, 500);
  });

export const waitForInteropInjectComponent = () =>
  new Promise<boolean>((resolve) => {
    var interval = setInterval(() => {
      if (windowWithInterop.reactInterop && typeof windowWithInterop.reactInterop.injectComponent === 'function') {
        clearInterval(interval);
        console.log('interop injectComponent found');

        resolve(true);
      } else {
        console.log('interop injectComponent not found, waiting...');
      }
    }, 500);
  });

export const waitForInteropAuthenticate = () =>
  new Promise<boolean>((resolve) => {
    var interval = setInterval(() => {
      if (windowWithInterop.reactInterop && typeof windowWithInterop.reactInterop.authenticate === 'function') {
        clearInterval(interval);
        console.log('interop Authenticate found');

        resolve(true);
      } else {
        console.log('interop Authenticate not found, waiting...');
      }
    }, 500);
  });
