import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { FormsModule } from '../../../../node_modules/@angular/forms';
import { HomepageViewComponent } from './views/homepage-view/homepage-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule],
  declarations: [HomepageViewComponent],
  exports: [HomepageViewComponent],
  entryComponents: [HomepageViewComponent],
})
export class HomepageModule extends ModuleBase {}
