import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { Incident } from './classes/Incident.class';
import { IncidentViewComponent } from './views/incident-view/incident-view.component';
import { IncidentsListViewComponent } from './views/incidents-list-view/incidents-list-view.component';
import { InteropViewComponent } from '../interop/interop-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule],
  declarations: [IncidentsListViewComponent, IncidentViewComponent],
  exports: [IncidentsListViewComponent, IncidentViewComponent],
  entryComponents: [IncidentsListViewComponent, IncidentViewComponent],
})
export class IncidentsModule extends ModuleBase {
  public static moduleName = 'invoicing';

  public ngOnInit() {
    ClassManager.registerClass('Incident', Incident);

    HomepageViewComponent.registerGroups({
      sales: {
        name: 'Ventes',
        backColor: 'rgb(149, 55, 53)',
        textColor: 'white',
        permission: 'home_groupe_ventes',
        children: [
          ...(config.interop.modules.incidents.list
            ? [
                {
                  name: 'Fiches incident',
                  new: true,
                  permission: 'home_bouton_ventes_incidents',
                  href: '/incidents',
                },
              ]
            : [
                {
                  name: 'Fiches incident',
                  permission: 'home_bouton_ventes_incidents',
                  component: IncidentsListViewComponent,
                },
              ]),
        ],
      },
    });

    return super.ngOnInit();
  }
}
