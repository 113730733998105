import { AccountingsService } from '../../services/accountings/accountings.service';
import { CurrenciesService } from '../../services/currencies/currencies.service';
import { DateTimeUtil } from '../utils/classes/DateTimeUtil.class';
import { Order } from './classes/Order.class';

export const createOrder = () => {
  const order = new Order();
  order.date = DateTimeUtil.toDateString(new Date());
  order.accounting = AccountingsService.currentAccounting;
  order.buy_currency = order.accounting ? order.accounting.default_currency : CurrenciesService.defaultCurrency;
  order.sell_currency = CurrenciesService.defaultCurrency;
  order.buy_xrate = order.buy_currency ? order.buy_currency.rate : 1;
  order.sell_xrate = order.sell_currency ? order.sell_currency.rate : 1;
  return order;
};
