import { ArticleBase } from '../../modules/articles/classes/ArticleBase.class';
import { ArticleViewComponent } from '../../modules/articles/views/article-view/article-view.component';
import { Customer } from '../../modules/customers/classes/Customer.class';
import { CustomerViewComponent } from '../../modules/customers/views/customer-view/customer-view.component';
import { Incident } from '../../modules/incidents/classes/Incident.class';
import { IncidentViewComponent } from '../../modules/incidents/views/incident-view/incident-view.component';
import { InteropViewComponent } from '../../modules/interop/interop-view.component';
import { Order } from '../../modules/orders/classes/Order.class';
import { OrderViewComponent } from '../../modules/orders/views/order-view/order-view.component';
import { Sale } from '../../modules/sales/classes/Sale.class';
import { SaleViewComponent } from '../../modules/sales/views/sale-view/sale-view.component';
import { StockMoveViewComponent } from 'src/app/modules/stocks/views/stock-move-view/stock-move-view.component';
import { Supplier } from '../../modules/suppliers/classes/Supplier.class';
import { SupplierViewComponent } from '../../modules/suppliers/views/supplier-view/supplier-view.component';
import { ViewBaseComponent } from './view-base.component';
import { config } from '../../classes/config';
import { createCustomerWithNewNumber } from '../../modules/customers/helpers';
import { createIncident } from '../../modules/incidents/helpers';
import { createOrder } from '../../modules/orders/helpers';
import { createSupplierWithNewNumber } from '../../modules/suppliers/helpers';

export type RouteDefinition = {
  pattern: RegExp | string;
  viewComponent: typeof ViewBaseComponent;
  viewArgs: any[] | ((matches: string[]) => Promise<any[]>);
  children?: RouteDefinition[];
};

export const getRouteDefinitions = (): RouteDefinition[] => [
  {
    pattern: 'articles',
    viewComponent: InteropViewComponent,
    viewArgs: ['ArticlesList', 'ArticlesListToolbar'],
  },
  {
    pattern:
      /^articles\/(new|[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12})$/,
    viewComponent: config.interop.modules.articles.details
      ? InteropViewComponent
      : ArticleViewComponent,
    viewArgs: config.interop.modules.articles.details
      ? ['ArticleDetails', 'ArticleDetailsToolbar']
      : async (matches) =>
          matches[1] === 'new' ? [null] : ArticleBase.load([matches[1]]),
  },
  {
    pattern: 'customers',
    viewComponent: InteropViewComponent,
    viewArgs: ['CustomersList', 'CustomersListToolbar'],
  },
  {
    pattern:
      /^customers\/(new|[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12})$/,
    viewComponent: config.interop.modules.customers.details
      ? InteropViewComponent
      : CustomerViewComponent,
    viewArgs: config.interop.modules.customers.details
      ? ['CustomerDetails', 'CustomerDetailsToolbar']
      : async (matches) =>
          matches[1] === 'new'
            ? createCustomerWithNewNumber()
            : Customer.load([matches[1]]),
  },
  {
    pattern: 'devices',
    viewComponent: InteropViewComponent,
    viewArgs: ['DevicesList', 'DevicesListToolbar'],
  },
  {
    pattern: 'incidents',
    viewComponent: InteropViewComponent,
    viewArgs: ['IncidentsList', 'IncidentsListsToolbar'],
  },
  {
    pattern:
      /^incidents\/(new|[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12})$/,
    viewComponent: config.interop.modules.incidents.details
      ? InteropViewComponent
      : IncidentViewComponent,
    viewArgs: config.interop.modules.incidents.details
      ? ['IncidentDetails', 'IncidentsDetailsToolbar']
      : async (matches) =>
          matches[1] === 'new' ? createIncident() : Incident.load([matches[1]]),
  },
  {
    pattern: 'nomenclatures',
    viewComponent: InteropViewComponent,
    viewArgs: ['NomenclaturesList', 'NomenclaturesListToolbar'],
  },
  {
    pattern: 'orders',
    viewComponent: InteropViewComponent,
    viewArgs: ['OrdersList', 'OrdersListToolbar'],
  },
  {
    pattern:
      /^orders\/(new|[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12})$/,
    viewComponent: config.interop.modules.orders.details
      ? InteropViewComponent
      : OrderViewComponent,
    viewArgs: config.interop.modules.orders.details
      ? ['OrderDetails', 'OrderDetailsToolbar']
      : async (matches) =>
          matches[1] === 'new' ? createOrder() : Order.load([matches[1]]),
  },
  {
    pattern: 'orders_followup',
    viewComponent: InteropViewComponent,
    viewArgs: ['OrdersFollowupList', 'OrdersFollowupListToolbar'],
  },
  {
    pattern: 'commercial_reimbursements',
    viewComponent: InteropViewComponent,
    viewArgs: ['ReimbursementsList'],
  },
  {
    pattern: /^commercial_reimbursements\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['ReimbursementsDetails'],
  },
  {
    pattern: 'commercial_invoices',
    viewComponent: InteropViewComponent,
    viewArgs: ['InvoicesList'],
  },
  {
    pattern: /^commercial_invoices\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['InvoiceDetails'],
  },
  {
    pattern: 'commercial_invoice',
    viewComponent: InteropViewComponent,
    viewArgs: ['Invoice'],
  },
  {
    pattern: 'logistic_invoices',
    viewComponent: InteropViewComponent,
    viewArgs: ['InvoicesList'],
  },
  {
    pattern: /^logistic_invoices\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['InvoiceDetails'],
  },
  {
    pattern: 'logistic_reimbursements',
    viewComponent: InteropViewComponent,
    viewArgs: ['ReimbursementsList'],
  },
  {
    pattern: /^logistic_reimbursements\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['ReimbursementsDetails'],
  },
  {
    pattern: 'rmas',
    viewComponent: InteropViewComponent,
    viewArgs: ['RmasList', 'RmasListToolbar'],
  },
  {
    pattern: 'sales',
    viewComponent: InteropViewComponent,
    viewArgs: ['SalesList', 'SalesListToolbar'],
  },
  {
    pattern:
      /^sales\/(new|[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12})$/,
    viewComponent: config.interop.modules.sales.details
      ? InteropViewComponent
      : SaleViewComponent,
    viewArgs: config.interop.modules.sales.details
      ? ['SaleDetails', 'SaleDetailsToolbar']
      : async (matches) =>
          matches[1] === 'new' ? [null] : Sale.load([matches[1]]),
  },
  {
    pattern: 'stock_moves_followup',
    viewComponent: InteropViewComponent,
    viewArgs: ['StockMovesFollowupList', 'StockMovesFollowupListToolbar'],
  },
  {
    pattern: 'stocks_adjustments',
    viewComponent: InteropViewComponent,
    viewArgs: ['StocksAdjustmentsList', 'StocksAdjustmentsListToolbar'],
  },
  {
    pattern: /^stocks_adjustments\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['StockAdjustmentDetails', 'StockAdjustmentDetailsToolbar'],
  },
  {
    pattern: 'stocks',
    viewComponent: InteropViewComponent,
    viewArgs: ['StocksList', 'StocksListToolbar'],
  },
  {
    pattern: 'stock_moves',
    viewComponent: InteropViewComponent,
    viewArgs: ['StockMovesList', 'StockMovesListToolbar'],
  },
  {
    pattern:
      /^stock_moves\/(new|[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12})$/,
    viewComponent: config.interop.modules.orders.details
      ? InteropViewComponent
      : StockMoveViewComponent,
    viewArgs: config.interop.modules.orders.details
      ? ['StockMoveDetails', 'StockMovesDetailsToolbar']
      : async (matches) =>
          matches[1] === 'new' ? createOrder() : Order.load([matches[1]]),
  },
  {
    pattern: 'suppliers',
    viewComponent: InteropViewComponent,
    viewArgs: ['SuppliersList', 'SuppliersListToolbar'],
  },
  {
    pattern:
      /^suppliers\/(new|[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12})$/,
    viewComponent: config.interop.modules.suppliers.details
      ? InteropViewComponent
      : SupplierViewComponent,
    viewArgs: config.interop.modules.suppliers.details
      ? ['SupplierDetails', 'SupplierDetailsToolbar']
      : async (matches) =>
          matches[1] === 'new'
            ? createSupplierWithNewNumber()
            : Supplier.load([matches[1]]),
  },
  {
    pattern: 'wages',
    viewComponent: InteropViewComponent,
    viewArgs: ['WagesList', 'WagesListToolbar'],
  },
  {
    pattern: 'customer_payments',
    viewComponent: InteropViewComponent,
    viewArgs: ['CustomerPaymentsList', 'CustomerPaymentsListToolbar'],
  },
  {
    pattern: 'supplier_payments',
    viewComponent: InteropViewComponent,
    viewArgs: ['SupplierPaymentsList', 'SupplierPaymentsListToolbar'],
  },
  {
    pattern: 'other-data',
    viewComponent: InteropViewComponent,
    viewArgs: ['OtherDataPage', 'OtherDataToolbar'],
  },
];
