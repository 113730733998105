import * as moment from 'moment';

import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ApiService } from '../../../../services/api/api.service';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { Customer } from 'src/app/modules/customers/classes/Customer.class';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { DataGridRowAction } from 'src/app/components/data-grid/classes/DataGridRowAction.class';
import { DomSanitizer } from '@angular/platform-browser';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { Invoice } from '../../../invoicing/classes/Invoice.class';
import { InvoiceViewComponent } from '../../../invoicing/views/invoice-view/invoice-view.component';
import { Order } from '../../../orders/classes/Order.class';
import { OrderViewComponent } from '../../../orders/views/order-view/order-view.component';
import { Sale } from '../../classes/Sale.class';
import { SaleViewComponent } from '../sale-view/sale-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';

@Component({
  selector: 'app-customer-history-view',
  templateUrl: './customer-history-view.component.html',
  styleUrls: ['./customer-history-view.component.scss'],
})
export class CustomerHistoryViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Historique des ventes client',
    data: this,
    elements: [
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: CustomerHistoryViewComponent) {
          view.filterArchived = !this.value;
        },
      },
      { type: 'spacing' },
    ],
  };

  private _historyType = 'sales';
  public get historyType() {
    return this._historyType;
  }
  public set historyType(value: string) {
    this._historyType = value;
    requestAnimationFrame(() => {
      if (this.salesGrid) this.salesGrid.resizeTableBody();
      if (this.ordersGrid) this.ordersGrid.resizeTableBody();
      if (this.invoicesGrid) this.invoicesGrid.resizeTableBody();
    });
  }
  public filterArchived: boolean = true;
  public sales: Sale[] = [];
  public orders: Order[] = [];
  public invoices: Invoice[] = [];
  public customersData: any = { items: [] };
  public timespansData: any = {
    items: [
      { name: '1 mois', unit: 1, duration: 'month' },
      { name: '3 mois', unit: 3, duration: 'months' },
      { name: '6 mois', unit: 6, duration: 'months' },
      { name: '1 an', unit: 1, duration: 'year' },
    ],
  };
  public settings: any = {
    customer: null,
    timespan: this.timespansData.items[2],
  };

  public customerBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      {
        title: 'Client',
        field: 'customer',
        type: 'foreign-list',
        readonly: false,
        multiSelect: false,
        listItems: this.customersData,
        listField: 'nameWithIdentifier',
        allowBlankValues: true,
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.reloadData();
        },
      },
      {
        title: 'Afficher les derniers ...',
        field: 'timespan',
        type: 'foreign-list',
        readonly: false,
        multiSelect: false,
        listItems: this.timespansData,
        listField: 'name',
        allowBlankValues: false,
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.reloadData();
        },
      },
    ],
  };

  public salesGridColumns = [
    { title: 'Date', type: 'date', field: 'date', width: 90 },
    { title: 'Numéro', field: 'numberWithVersion', width: 200 },
    { title: 'Représentant', field: 'merchant.numberAndName', width: 130 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'N° de commande client', field: 'customer_reference', width: 200 },
    { title: 'Désignation', field: 'article.designation', width: 600 },
    { title: 'Nomenclature', field: 'article.nomenclature.name', width: 300 },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Format</span>\n<span style="color:rgb(0,164,48);">Marque et modèle</span>\n<span style="color:rgb(214, 188, 0);">Description</span>'
      ),
      field: 'article.field1',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Matière</span>\n<span style="color:rgb(0,164,48);">Technologie</span>'
      ),
      field: 'article.field2',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Matière (clients)</span>\n<span style="color:rgb(0,164,48);">Résolution</span>'
      ),
      field: 'article.field3',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Impression</span>\n<span style="color:rgb(0,164,48);">Vitesse max.</span>'
      ),
      field: 'article.field4',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">ø du mandrin / rouleau</span>\n<span style="color:rgb(0,164,48);">Largeur du média</span>'
      ),
      field: 'article.field5',
      width: 170,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Présentation</span>\n<span style="color:rgb(0,164,48);">Epaisseur du média</span>'
      ),
      field: 'article.field6',
      width: 120,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Nb. d\'éti./feuil./bob./parav.</span>\n<span style="color:rgb(0,164,48);">Mémoire</span>'
      ),
      field: 'article.field7',
      width: 120,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Esp. vertical/horizontal</span>\n<span style="color:rgb(0,164,48);">Couteau</span>'
      ),
      field: 'article.field8',
      width: 100,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Sens d\'enroulement</span>\n<span style="color:rgb(0,164,48);">Interface (standard)</span>'
      ),
      field: 'article.field9',
      width: 150,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Laize</span>\n<span style="color:rgb(0,164,48);">Programmation</span>'
      ),
      field: 'article.field10',
      width: 90,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Pinfeed</span>\n<span style="color:rgb(0,164,48);">Options</span>'
      ),
      field: 'article.field11',
      width: 100,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Perforations / Façonnage</span>\n<span style="color:rgb(0,164,48);">Construction</span>'
      ),
      field: 'article.field12',
      width: 200,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Autres</span>\n<span style="color:rgb(0,164,48);">Garantie</span>'
      ),
      field: 'article.field13',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml(
        '<span style="color:rgb(0,156,218);">Conditionnement</span>\n<span style="color:rgb(0,164,48);">Autres</span>'
      ),
      field: 'article.field14',
      width: 250,
    },
    {
      title: this.sr.bypassSecurityTrustHtml('Facturations'),
      field: 'invoicings',
      width: 100,
    },
    {
      title: this.sr.bypassSecurityTrustHtml('Livraisons'),
      field: 'deliveries',
      width: 100,
    },
    {
      title: this.sr.bypassSecurityTrustHtml('Stockage'),
      field: 'storage',
      width: 100,
    },
  ];
  public ordersGridColumns = [
    {
      title: 'Numéro de\ncommande',
      field: 'number',
      textColor: (item: Order) => {
        return;
      },
      width: 200,
    },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Date', type: 'date', field: 'date', width: 80 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 150 },
    { title: 'Désignation', field: 'article.designation', width: 500 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    {
      title: "Date de l'offre\nfournisseur",
      type: 'date',
      field: 'supplier_date',
      width: 80,
    },
    {
      title: "N° de l'offre\nfournisseur",
      field: 'supplier_reference',
      width: 150,
    },
    {
      title: 'N° ou type\nde commande\nclient',
      field: 'customer_reference',
      width: 150,
    },
    { title: 'N° de dossier\ncommercial', field: 'sale.number', width: 200 },
    { title: 'Représentant', field: 'merchant.numberAndName', width: 150 },
    // { title: 'Livraisons', field: 'deliveries' },
    // { title: 'Stockage', field: 'storage' },
    {
      title: "Prix\nd'achat\nà l'unité\nH.T.V.A.",
      field: 'converted_unitBuyPrice',
      type: 'number',
      decimalsCount: 5,
      currencyField: 'buy_currency',
      width: 130,
    },
    {
      title: 'Frais de\ngestion',
      field: 'converted_internal_fees',
      type: 'number',
      decimalsCount: 2,
      currencyField: 'sell_currency',
      width: 130,
    },
    {
      title: "Prix de\nvente\nà l'unité\nH.T.V.A.",
      field: 'converted_sell_price',
      type: 'number',
      decimalsCount: 5,
      currencyField: 'sell_currency',
      width: 130,
    },
    { title: 'Nb. de\nfactu-\nrations', field: 'invoicings', width: 80 },
    {
      title: 'Montant\ntotal\nH.T.V.A.',
      field: 'converted_totalAmount',
      type: 'number',
      decimalsCount: 2,
      currencyField: 'sell_currency',
      headerBackColor: 'rgb(255,192,0)',
      backColor: 'rgb(255,255,153)',
      width: 130,
    },
    {
      title: 'Montant de\nla marge\nbrute H.T.V.A.',
      field: 'rawMargin',
      type: 'number',
      decimalsCount: 2,
      unit: '€',
      headerBackColor: 'rgb(255,192,0)',
      backColor: 'rgb(255,255,153)',
      width: 130,
    },
    {
      title: 'Marge\nbrute',
      field: 'rawMarginPercentage',
      type: 'number',
      decimalsCount: 2,
      unit: '%',
      headerBackColor: 'rgb(255,192,0)',
      backColor: 'rgb(255,255,153)',
      width: 130,
    },
    {
      title: 'Montant\nacheté\nH.T.V.A.',
      field: 'converted_total_buyPrice',
      type: 'number',
      decimalsCount: 2,
      currencyField: 'buy_currency',
      headerBackColor: 'rgb(255,192,0)',
      backColor: 'rgb(255,255,153)',
      width: 130,
    },
    {
      title: 'N° de facture',
      field: 'invoices_list',
      headerBackColor: 'rgb(255,192,0)',
      backColor: 'rgb(255,255,153)',
      width: 200,
    },
    { title: 'Remarques internes', field: 'internal_remarks', width: 500 },
  ];
  public totalAmountColumn: any = {
    title: 'Montant total\nfacturé H.T.V.A.',
    field: 'converted_totalAmount',
    type: 'number',
    currencyField: 'sell_currency',
    decimalsCount: 2,
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public vatAmountColumn: any = {
    title: 'Montant de\nla T.V.A.',
    field: 'converted_vatAmount',
    type: 'number',
    currencyField: 'sell_currency',
    decimalsCount: 2,
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public totalWithVatAmountColumn: any = {
    title: 'Montant total\nfacturé T.V.A.C.',
    field: 'converted_totalWithTax',
    type: 'number',
    currencyField: 'sell_currency',
    decimalsCount: 2,
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public rawMarginColumn: any = {
    title: 'Montant de\nla marge\nbrute H.T.V.A.',
    field: 'converted_total_rawMargin',
    type: 'number',
    decimalsCount: 2,
    unit: '€',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public rawMarginPercentageColumn: any = {
    title: 'Marge brute',
    field: 'total_rawMarginPerc',
    type: 'number',
    decimalsCount: 2,
    unit: '%',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public netMarginColumn: any = {
    title: 'Montant de\nla marge\nnette H.T.V.A.',
    field: 'converted_total_netMargin',
    type: 'number',
    decimalsCount: 2,
    unit: '€',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public netMarginPercentageColumn: any = {
    title: 'Marge nette',
    field: 'total_netMarginPerc',
    type: 'number',
    decimalsCount: 2,
    unit: '%',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public totalBuyPriceColumn: any = {
    title: 'Montant\nacheté H.T.V.A.',
    field: 'converted_total_buyPrice',
    type: 'number',
    decimalsCount: 2,
    currencyField: 'buy_currency',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public invoicesGridColumns = [
    { title: 'N° de facture', field: 'number', width: 200 },
    { title: 'Date', field: 'date', type: 'date', width: 80 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Représentant', field: 'merchant.numberAndName', width: 150 },
    { title: 'N° de commande', field: 'order.number', width: 200 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Désignation', field: 'article.designation', width: 450 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 150 },
    {
      title: 'Prix de vente\nunitaire',
      field: 'converted_sell_price',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 5,
      width: 130,
    },
    {
      title: 'N° de commande\ndu client',
      field: 'customer_reference',
      width: 150,
    },
    { title: 'N° du bon\nde livraison', field: 'delivery_number', width: 200 },
    {
      title: 'Date de\nlivraison',
      field: 'delivery_date',
      type: 'text',
      width: 80,
    },
    { title: 'Conditions de\npaiement', field: 'order.deadline', width: 150 },
    {
      title: 'N° du dossier\ncommercial',
      field: 'order.sale.number',
      width: 200,
    },
    {
      title: 'Paiement\nfournisseur',
      field: 'has_supplier_payment',
      width: 50,
    },
    this.totalAmountColumn,
    this.vatAmountColumn,
    this.totalWithVatAmountColumn,
    this.rawMarginColumn,
    this.rawMarginPercentageColumn,
    this.netMarginColumn,
    this.netMarginPercentageColumn,
    {
      title: 'Frais de\ngestion',
      field: 'converted_total_internalFees',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 5,
      width: 100,
    },
    {
      title: 'Frais de\ncompo',
      field: 'converted_total_externalFees',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 5,
      width: 100,
    },
    this.totalBuyPriceColumn,
  ];

  public salesGridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.saleDblClick(event, item);
      },
    },
  ];
  public ordersGridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.orderDblClick(event, item);
      },
    },
  ];
  public invoicesGridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.invoiceDblClick(event, item);
      },
    },
  ];

  @ViewChild('salesGrid') salesGrid: DataGridComponent;
  @ViewChild('ordersGrid') ordersGrid: DataGridComponent;
  @ViewChild('invoicesGrid') invoicesGrid: DataGridComponent;

  public constructor(private sr: DomSanitizer, protected ref: ChangeDetectorRef, protected elRef: ElementRef) {
    super(ref, elRef);
  }

  ngOnInit() {
    let self = this;
    Customer.load(null, ['name'], null, false, 'valid = 1').then(
      function (result) {
        self.customersData.items = result;
      },
      function (err) {
        console.log(err);
      }
    );
    this.reloadData();
  }

  onAccountingChanged() {
    this.reloadData();
  }

  public reloadData() {
    this.sales = [];
    this.orders = [];
    this.invoices = [];
    if (this.settings.customer) {
      let conditions: string[] = [];
      // current accounting condition
      if (AccountingsService.currentAccounting)
        conditions.push(`id_accounting='${AccountingsService.currentAccounting.id}'`);
      // see all merchants condition
      if (!CredentialsService.isUserAllowed('dossier_commerciaux_voir_tous_representants'))
        conditions.push(`id_merchant='${CredentialsService.loggedMerchant.id}'`);
      conditions.push(
        'date >= "' +
          moment().subtract(this.settings.timespan.unit, this.settings.timespan.duration).format('YYYY-MM-DD') +
          '"'
      );
      Sale.load(
        null,
        ['~date'],
        null,
        false,
        [...conditions, `id_customer = "${this.settings.customer.id}"`].join(' AND ')
      ).then(
        (result) => {
          this.sales = result;
        },
        (err) => {
          console.error(err);
        }
      );
      Order.load(
        null,
        ['~date'],
        null,
        false,
        [...conditions, `id_customer = "${this.settings.customer.id}"`].join(' AND ')
      ).then(
        (result) => {
          this.orders = result;
        },
        (err) => {
          console.error(err);
        }
      );
      ApiService.callModule('customers', 'invoicesList', {
        id_customer: this.settings.customer.id,
      }).then(
        (response) => {
          if (response.result === 'success' && Array.isArray(response.details) && response.details.length) {
            Invoice.load(response.details, ['~date'], null, false, conditions.join(' AND ')).then(
              (result) => {
                this.invoices = result;
              },
              (err) => {
                console.error(err);
              }
            );
          }
        },
        (error) => console.error(error)
      );
    }
  }

  public get filteredSales(): Sale[] {
    if (!this.filterArchived) return this.sales;
    else
      return this.sales.filter((value: Sale, index: number, array: Sale[]) => !value.archived || value.archived != 1);
  }
  public get filteredOrders(): Order[] {
    if (!this.filterArchived) return this.orders;
    else
      return this.orders.filter(
        (value: Order, index: number, array: Order[]) => !value.archived || value.archived != 1
      );
  }

  saleDblClick(event, sale) {
    ViewsComponent.openView(SaleViewComponent, sale);
  }
  orderDblClick(event, order) {
    ViewsComponent.openView(OrderViewComponent, order);
  }
  invoiceDblClick(event, invoice) {
    ViewsComponent.openView(InvoiceViewComponent, invoice);
  }

  gridRowStyle(item: Sale | Order) {
    return item && item.archived == 1
      ? {
          color: 'gray',
          'font-style': 'italic',
        }
      : null;
  }
}
