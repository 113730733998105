import * as $ from 'jquery';

import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  dateColumn,
  emailColumn,
  phoneColumn,
  textColumn,
} from '../../../../components/data-grid2/classes/DataGridColumn.class';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Address } from '../../../addresses/classes/Address.class';
import { ApiService } from '../../../../services/api/api.service';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { BusinessUtil } from '../../../utils/classes/BusinessUtil.class';
import { Country } from '../../../other-data/classes/Country.class';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { Customer } from '../../classes/Customer.class';
import { CustomerCategory } from '../../classes/CustomerCategory.class';
import { DataGrid2Component } from '../../../../components/data-grid2/data-grid2.component';
import { Deadline } from '../../../other-data/classes/Deadline.class';
import { DialogButton } from '../../../../components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../../../../components/dialogs/dialogs.component';
import { Form } from '../../../../classes/forms/Form.class';
import { FormValidator } from '../../../../classes/forms/FormValidator.class';
import { Incident } from '../../../incidents/classes/Incident.class';
import { InfoBlock } from '../../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../../components/info-block/classes/InfoBlockField.class';
import { Lang } from '../../../other-data/classes/Lang.class';
import { LoadingPromise } from '../../../../classes/objects/LoadingPromise.class';
import { Merchant } from '../../../other-data/classes/Merchant.class';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { Postcode } from '../../../other-data/classes/Postcode.class';
import { ResetPasswordModalComponent } from '../reset-password-modal/reset-password-modal.component';
import { Sale } from '../../../../modules/sales/classes/Sale.class';
import { ServerApi } from '../../../../classes/api/ServerApi.class';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss'],
})
export class CustomerViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_basededonnees_fiche_client';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Fiche client',
    data: this,
    onPrevPage: () => {
      if (!this.customerCopy || this.customerCopy.changed !== true) return true;
      else {
        DialogsComponent.display({
          icon: 'question',
          title: 'Enregistrer les modifications',
          message: 'Des modifications ont été effectuées.<br/>Voulez-vous enregistrer les modifications ?',
          buttons: DialogButton.yesNoCancelButtons,
        }).then((result: any) => {
          if (result === DialogButton.RESULT_YES) {
            this.save().then((result2: any) => {
              ViewsComponent.closeView();
            });
          } else if (result !== DialogButton.RESULT_CANCEL) ViewsComponent.closeView();
        });
        return false;
      }
    },
    elements: [
      { type: 'separator' },
      {
        name: 'saveButton',
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: CustomerViewComponent) {
          view.save();
        },
        // access: this.writeAccess
      },
      { type: 'separator' },
      {
        name: 'duplicateButton',
        type: 'button',
        text: 'Dupliquer',
        icon: 'copy',
        click: function (view: CustomerViewComponent) {
          view.duplicate();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        name: 'validateButton',
        type: 'button',
        text: 'Valider',
        icon: ' fas fa-check',
        click: function (view: CustomerViewComponent) {
          view.validate();
        },
        // access: this.writeAccess,
        visible: false,
      },
      { type: 'separator-large' },
      {
        name: 'copyAddress',
        type: 'button',
        text: "Copier<br/>l'adresse",
        icon: 'copy',
        click: (view: CustomerViewComponent) => {
          if (!view.lastFocusAddr)
            NotificationsComponent.push({
              type: 'warning',
              title: 'Aucune adresse sélectionnée',
              summary: "Sélectionnez d'abord une adresse à copier en cliquant dessus.",
            });
          else view.copyAddress(this.lastFocusAddr);
        },
        // access: this.writeAccess
      },
      { type: 'separator' },
      {
        name: 'pasteAddress',
        type: 'button',
        text: "Coller<br/>l'adresse",
        icon: 'paste',
        click: function (view: CustomerViewComponent) {
          if (!view.addressCopy)
            NotificationsComponent.push({
              type: 'warning',
              title: 'Aucune adresse copiée',
              summary: "Copiez d'abord une adresse pour pouvoir coller.",
            });
          else view.pasteAddress(view.lastFocusAddr);
        },
        // access: this.writeAccess
      },
      { type: 'separator-large' },
      {
        name: 'resetPasswordButton',
        type: 'button',
        text: 'Imprimer<br/>la fiche',
        icon: 'print',
        click: function (view: CustomerViewComponent) {
          view.printView();
        },
        // access: this.writeAccess
      },
      { type: 'separator-large' },
      {
        name: 'openStockPlatform',
        type: 'button',
        text: 'Ouvrir la<br/>fiche des stocks',
        icon: 'cubes',
        click: function (view: CustomerViewComponent) {
          view.openStockPlatform();
        },
        // access: this.writeAccess
      },
      { type: 'separator' },
      {
        name: 'resetPasswordButton',
        type: 'button',
        text: 'Réinitialiser<br/>le mot de passe',
        icon: ' fas fa-key',
        click: function (view: CustomerViewComponent) {
          view.resetPassword();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        name: 'copyDeadlinesButton',
        type: 'button',
        text: 'Copier vers des<br/>dossiers commerciaux',
        icon: ' fas fa-copy',
        click: function (view: CustomerViewComponent) {
          view.showCopyToSales();
        },
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public customer: Customer = null;
  public customerCopy: Customer = null;

  public nextNumbers: any = { items: [] };
  public parentCustomersData: any = { items: [] };
  public merchantsData: any = { items: [] };
  public deadlinesData: any = { items: [] };
  public categoriesData: any = { items: [] };
  public langsData: any = { items: [] };
  public countriesData: any = { items: [] };
  public postcodes: Postcode[] = [];
  public ordinatorPostcodes: any = { items: [] };
  public billingPostcodes: any = { items: [] };
  public deliveryPostcodes: any = { items: [] };
  public addressesPostcodes: any[] = [];
  public allPostcodes: any = { items: [] };

  public turnoversVisible: boolean = false;
  public turnovers: any[] = [];
  public turnoverColumns: any[] = [
    { title: 'Année', field: 'year', width: 80 },
    { title: 'Dossiers\ncommerciaux', field: 'sales_count', width: 100 },
    { title: 'Nombre de\ncommandes', field: 'orders_count', width: 120 },
    {
      title: 'Total\ncommandes\nHTVA',
      field: 'orders_amount',
      type: 'number',
      decimalsCount: 2,
      unit: ' €',
      width: 120,
    },
    { title: 'Nombre de\nfactures', field: 'invoices_count', width: 120 },
    {
      title: 'Total\nfactures\nHTVA',
      field: 'invoices_amount',
      type: 'number',
      decimalsCount: 2,
      unit: ' €',
      width: 120,
    },
  ];

  public incidentsVisible: boolean = false;
  public incidents: any[] = [];
  public incidentsColumns: any[] = [
    { title: 'Date', field: 'date', type: 'date', width: 80 },
    { title: 'N° de commande', field: 'order.number', width: 180 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 180 },
    {
      title: 'Montant\nestimé',
      field: 'amount',
      type: 'number',
      decimalsCount: 2,
      unit: ' €',
      width: 120,
    },
    { title: 'Clôturé', field: 'closed', type: 'checkbox', width: 80 },
  ];
  incidentRowStyle(item: Incident) {
    if (item && item.closed == 0) return { color: 'rgb(192,0,0)' };
    else if (item && item.closed == 1) return { color: 'rgb(0,192,0)' };
    else return null;
  }

  @ViewChild('nameBlockComponent') nameBlockComponent: InfoBlockComponent;
  @ViewChild('generalBlockComponent') generalBlockComponent: InfoBlockComponent;
  @ViewChild('vatBlockComponent') vatBlockComponent: InfoBlockComponent;
  // @ViewChild('mailsBlockComponent') mailsBlockComponent: InfoBlockComponent;
  @ViewChild('ordinatorBlockComponent')
  ordinatorBlockComponent: InfoBlockComponent;
  @ViewChild('billingBlockComponent') billingBlockComponent: InfoBlockComponent;
  @ViewChild('deliveryBlockComponent')
  deliveryBlockComponent: InfoBlockComponent;
  @ViewChild('bankBlockComponent') bankBlockComponent: InfoBlockComponent;
  @ViewChild('remarksBlockComponent') remarksBlockComponent: InfoBlockComponent;
  @ViewChildren('contactBlockComponent')
  contactBlocksComponents: QueryList<InfoBlockComponent>;
  // @ViewChildren('addressBlockComponent') addressBlocksComponents: QueryList<InfoBlockComponent>;
  @ViewChildren('deliveryAddressesBlockComponent')
  deliveryAddressesBlockComponent: QueryList<InfoBlockComponent>;
  @ViewChildren('invoicingAddressesBlockComponent')
  invoicingAddressesBlockComponent: QueryList<InfoBlockComponent>;

  @ViewChild('loginForm') loginForm: ElementRef;
  public showStockPlatform: boolean = false;

  public nameBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(220,230,241)',
    textColor: 'black',
    fields: [
      { title: 'Nom du client', field: 'name' },
      {
        title: 'Parent',
        field: 'parent',
        type: 'foreign-list',
        allowBlankValues: true,
        multiSelect: false,
        listItems: this.parentCustomersData,
        listField: 'nameWithIdentifier',
        nullText: '(Aucun)',
      },
      { title: 'ID pour la compta', field: 'bob_id' },
      {
        title: 'Identifiant',
        field: 'identifier',
        comment:
          "Détail permettant de distinguer 2 clients semblables. S'affichera à côté du nom du client dans les listes, afin de le reconnaitre. (Usage interne uniquement, n'apparaitra pas sur les documents)",
      },
    ],
  };
  public generalBlock: InfoBlock = {
    title: 'Critères\nde tri',
    backColor: 'rgb(184,204,228)',
    textColor: 'black',
    fields: [
      {
        title: 'N° de client',
        field: 'number',
        type: 'combo-box',
        listItems: this.nextNumbers,
        listField: 'full_number',
        disableFiltering: true,
        readonly: !CredentialsService.isUserAllowed('customer_view_edit_number'),
      },
      {
        title: 'Catégorie',
        field: 'category',
        type: 'foreign-list',
        allowBlankValues: true,
        multiSelect: false,
        listItems: this.categoriesData,
        listField: 'name',
        nullText: '(Aucune)',
        //   readonly: !LoginViewComponent.instance.isUserAllowed('customer_view_edit_category')
      },
      {
        title: 'Langue',
        field: 'lang',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.langsData,
        listField: 'name',
        nullText: '(Aucune)',
      },
      {
        title: 'Conditions de paiement',
        field: 'deadline',
        type: 'combo-box',
        disableFiltering: true,
        multiSelect: false,
        listItems: this.deadlinesData,
        listField: 'name',
        nullText: '(Aucun)',
      },
    ],
  };
  public vatBlock: InfoBlock = {
    title: 'TVA',
    backColor: 'rgb(149, 179, 215)',
    textColor: 'black',
    fields: [
      {
        title: 'N° TVA',
        field: 'vat_number',
        type: 'nullable-text',
        nullText: '(Pas de TVA)',
        change: this.formatVatNumber,
        comment:
          "Cochez la case et entrez ici le numéro de TVA du client, le cas échéant.<br/>Si le client est <b>non assujetti</b>, décocher la case.<br/>S'il est assujetti mais qu'il faut facturer sans TVA, indiquer quand même le numéro TVA du client, et indiquer <b>0%</b> dans le champ <b>Taux TVA</b>.",
      },
      {
        title: 'Taux TVA',
        field: 'vat',
        type: 'number',
        decimalsCount: 1,
        numberUnit: '%',
        textAlign: 'left',
        comment: 'Insérez ici le taux de TVA à appliquer au client pour la facturation.',
      },
    ],
  };
  // public mailsBlock: InfoBlock = {
  //     title: "",
  //     backColor: 'rgb(192,80,77)',
  //     textColor: 'white',
  //     fields: [
  //         { title: 'Mail compta.', field: 'complaint_email' },
  //         { title: 'Mail facturation', field: 'billing_email' }
  //     ]
  // };
  public ordinatorBlock: InfoBlock = {
    backColor: 'rgb(122,157,199)',
    textColor: 'black',
    click: (component: InfoBlockComponent, block: InfoBlock, object: any, event: any) => {
      this.addrClick(component, block, object, event);
    },
    fields: [
      {
        title: 'Destinataire',
        field: 'name1',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
      },
      {
        title: 'Service',
        field: 'name2',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
      },
      { title: 'Adresse', field: 'address1' },
      { title: 'Adresse (2)', field: 'address2' },
      {
        title: 'Code postal',
        field: 'postcode',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcodes: Postcode[] = addr.postcode
            ? this.postcodes.filter((value: Postcode, index: number, obj: Postcode[]) => {
                return value.postcode == addr.postcode;
              })
            : this.postcodes;
          this.ordinatorPostcodes.items = postcodes;
          if (postcodes.length > 1) addr.city = null;
          else if (postcodes.length == 1) addr.city = postcodes[0].city;
        },
      },
      {
        title: 'Commune',
        field: 'city',
        type: 'combo-box',
        listItems: this.ordinatorPostcodes,
        listField: 'city',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcode: Postcode = this.postcodes.find((value: Postcode, index: number, obj: Postcode[]) => {
            return value.city == event;
          });
          if (postcode != null) addr.postcode = postcode.postcode;
        },
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          if (event instanceof Postcode) addr.postcode = event.postcode;
        },
      },
      {
        title: 'Pays',
        field: 'country',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.countriesData,
        listField: 'name',
      },
      { title: 'Téléphone', field: 'phone', change: this.phoneChange },
      { title: 'Fax', field: 'fax', change: this.phoneChange },
      { title: 'E-mail', field: 'email' },
    ],
  };
  public billingBlock: InfoBlock = {
    click: (component: InfoBlockComponent, block: InfoBlock, object: any, event: any) => {
      this.addrClick(component, block, object, event);
    },
    fields: [
      {
        title: 'Destinataire',
        field: 'name1',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
      },
      {
        title: 'Service',
        field: 'name2',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
      },
      { title: 'Adresse', field: 'address1' },
      { title: 'Adresse (2)', field: 'address2' },
      {
        title: 'Code postal',
        field: 'postcode',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcodes: Postcode[] = addr.postcode
            ? this.postcodes.filter((value: Postcode, index: number, obj: Postcode[]) => {
                return value.postcode == addr.postcode;
              })
            : this.postcodes;
          this.billingPostcodes.items = postcodes;
          if (postcodes.length > 1) addr.city = null;
          else if (postcodes.length == 1) addr.city = postcodes[0].city;
        },
      },
      {
        title: 'Commune',
        field: 'city',
        type: 'combo-box',
        listItems: this.billingPostcodes,
        listField: 'city',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcode: Postcode = this.postcodes.find((value: Postcode, index: number, obj: Postcode[]) => {
            return value.city == event;
          });
          if (postcode != null) addr.postcode = postcode.postcode;
        },
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          if (event instanceof Postcode) addr.postcode = event.postcode;
        },
      },
      {
        title: 'Pays',
        field: 'country',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.countriesData,
        listField: 'name',
      },
      { title: 'Téléphone', field: 'phone', change: this.phoneChange },
      { title: 'Fax', field: 'fax', change: this.phoneChange },
      // { title: "TVA", field: 'vat' },,
      { title: 'E-mail compta.', field: 'accounting_email' },
      { title: 'E-mail facturation', field: 'invoicing_email' },
      { title: 'Envoi par mail', field: 'invoicing_by_mail', type: 'checkbox' },
      {
        title: 'Envoi par courrier',
        field: 'invoicing_by_paper',
        type: 'checkbox',
      },
    ],
  };
  public deliveryBlock: InfoBlock = {
    click: (component: InfoBlockComponent, block: InfoBlock, object: any, event: any) => {
      this.addrClick(component, block, object, event);
    },
    fields: [
      {
        title: 'Destinataire',
        field: 'name1',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
      },
      {
        title: 'Service',
        field: 'name2',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
      },
      { title: 'Adresse', field: 'address1' },
      { title: 'Adresse (2)', field: 'address2' },
      {
        title: 'Code postal',
        field: 'postcode',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcodes: Postcode[] = addr.postcode
            ? this.postcodes.filter((value: Postcode, index: number, obj: Postcode[]) => {
                return value.postcode == addr.postcode;
              })
            : this.postcodes;
          this.deliveryPostcodes.items = postcodes;
          if (postcodes.length > 1) addr.city = null;
          else if (postcodes.length == 1) addr.city = postcodes[0].city;
        },
      },
      {
        title: 'Commune',
        field: 'city',
        type: 'combo-box',
        listItems: this.deliveryPostcodes,
        listField: 'city',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcode: Postcode = this.postcodes.find((value: Postcode, index: number, obj: Postcode[]) => {
            return value.city == event;
          });
          if (postcode != null) addr.postcode = postcode.postcode;
        },
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          if (event instanceof Postcode) addr.postcode = event.postcode;
        },
      },
      {
        title: 'Pays',
        field: 'country',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.countriesData,
        listField: 'name',
      },
      { title: 'Téléphone', field: 'phone', change: this.phoneChange },
      { title: 'Fax', field: 'fax', change: this.phoneChange },
      { title: 'Horaires', field: 'opening_hours' },
    ],
  };
  public deliveryAddress2Block: InfoBlock = {
    click: (component: InfoBlockComponent, block: InfoBlock, object: any, event: any) => {
      this.addrClick(component, block, object, event);
    },
    fields: [
      { title: 'Désignation', field: 'title' },
      {
        title: 'Destinataire',
        field: 'name1',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
        commentLeft: true,
      },
      {
        title: 'Service',
        field: 'name2',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
        commentLeft: true,
      },
      { title: 'Adresse', field: 'address1' },
      { title: 'Adresse (2)', field: 'address2' },
      {
        title: 'Code postal',
        field: 'postcode',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcodes: Postcode[] = addr.postcode
            ? this.postcodes.filter((value: Postcode, index: number, obj: Postcode[]) => {
                return value.postcode == addr.postcode;
              })
            : this.postcodes;
          let addrIndex = this.customerCopy.delivery_addresses.indexOf(addr);
          if (!this.addressesPostcodes[addrIndex]) this.addressesPostcodes[addrIndex] = { items: [] };
          this.addressesPostcodes[addrIndex].items = postcodes;
          block.fields[6].listItems = this.addressesPostcodes[addrIndex];
          if (postcodes.length > 1) addr.city = null;
          else if (postcodes.length == 1) addr.city = postcodes[0].city;
        },
      },
      {
        title: 'Commune',
        field: 'city',
        type: 'combo-box',
        listItems: this.allPostcodes,
        listField: 'city',
        mousedown: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let addrIndex = this.customerCopy.delivery_addresses.indexOf(addr);
          block.fields[6].listItems = this.addressesPostcodes[addrIndex];
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcode: Postcode = this.postcodes.find((value: Postcode, index: number, obj: Postcode[]) => {
            return value.city == event;
          });
          if (postcode != null) addr.postcode = postcode.postcode;
        },
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          if (event instanceof Postcode) addr.postcode = event.postcode;
        },
      },
      {
        title: 'Pays',
        field: 'country',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.countriesData,
        listField: 'name',
      },
      { title: 'Téléphone', field: 'phone', change: this.phoneChange },
      { title: 'Fax', field: 'fax', change: this.phoneChange },
      { title: 'Horaires', field: 'opening_hours' },
    ],
  };
  public invoicingAddress2Block: InfoBlock = {
    click: (component: InfoBlockComponent, block: InfoBlock, object: any, event: any) => {
      this.addrClick(component, block, object, event);
    },
    fields: [
      { title: 'Désignation', field: 'title' },
      {
        title: 'Destinataire',
        field: 'name1',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
        commentLeft: true,
      },
      {
        title: 'Service',
        field: 'name2',
        comment: "Ne pas répéter ici le nom du client, il s'ajoutera automatiquement sur les documents.",
        commentLeft: true,
      },
      { title: 'Adresse', field: 'address1' },
      { title: 'Adresse (2)', field: 'address2' },
      {
        title: 'Code postal',
        field: 'postcode',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcodes: Postcode[] = addr.postcode
            ? this.postcodes.filter((value: Postcode, index: number, obj: Postcode[]) => {
                return value.postcode == addr.postcode;
              })
            : this.postcodes;
          let addrIndex = this.customerCopy.invoicing_addresses.indexOf(addr);
          if (!this.addressesPostcodes[addrIndex]) this.addressesPostcodes[addrIndex] = { items: [] };
          this.addressesPostcodes[addrIndex].items = postcodes;
          block.fields[6].listItems = this.addressesPostcodes[addrIndex];
          if (postcodes.length > 1) addr.city = null;
          else if (postcodes.length == 1) addr.city = postcodes[0].city;
        },
      },
      {
        title: 'Commune',
        field: 'city',
        type: 'combo-box',
        listItems: this.allPostcodes,
        listField: 'city',
        mousedown: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let addrIndex = this.customerCopy.invoicing_addresses.indexOf(addr);
          block.fields[6].listItems = this.addressesPostcodes[addrIndex];
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          let postcode: Postcode = this.postcodes.find((value: Postcode, index: number, obj: Postcode[]) => {
            return value.city == event;
          });
          if (postcode != null) addr.postcode = postcode.postcode;
        },
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, data, event) => {
          let addr: Address = data as Address;
          if (event instanceof Postcode) addr.postcode = event.postcode;
        },
      },
      {
        title: 'Pays',
        field: 'country',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.countriesData,
        listField: 'name',
      },
      { title: 'Téléphone', field: 'phone', change: this.phoneChange },
      { title: 'Fax', field: 'fax', change: this.phoneChange },
      { title: 'E-mail compta.', field: 'accounting_email' },
      { title: 'E-mail facturation', field: 'invoicing_email' },
      { title: 'Envoi par mail', field: 'invoicing_by_mail', type: 'checkbox' },
      {
        title: 'Envoi par courrier',
        field: 'invoicing_by_paper',
        type: 'checkbox',
      },
    ],
  };
  public contactBlock: InfoBlock = {
    click: this.addrClick,
    fields: [
      { title: 'Contact', field: 'name1' },
      { title: 'Service', field: 'name2' },
      { title: 'Téléphone', field: 'phone', change: this.phoneChange },
      { title: 'Fax', field: 'fax', change: this.phoneChange },
      { title: 'GSM', field: 'mobile', change: this.phoneChange },
      { title: 'Email', field: 'email' },
    ],
  };
  phoneChange(component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) {
    let phone_elem: any = component.getInputElement('phone');
    if (phone_elem) {
      phone_elem = phone_elem.nativeElement;
      let phone_value: string = phone_elem.value.replace(/[^\+0-9]/g, '');
      if (phone_value.startsWith('+32')) {
        /*if (phone_value.length == 12) $(phone_elem).mask('+00 000 00 00 00');
        else if (phone_value.length == 11) {
            if (/^\+32[2349]/.test(phone_value)) $(phone_elem).mask('+00 0 000 00 000');
            else $(phone_elem).mask('+00 00 00 00 000');
        }*/
        //else $(phone_elem).unmask();
      }
      //else $(phone_elem).unmask();
    }
  }
  public bankBlock: InfoBlock = {
    title: 'Banque',
    backColor: 'rgb(15, 36, 62)',
    textColor: 'white',
    fields: [
      { title: 'IBAN', field: 'iban' },
      { title: 'BIC', field: 'bic' },
    ],
  };
  formatVatNumber(component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) {
    let vat_elem: any = component.getInputElement('vat_number');
    if (vat_elem) {
      vat_elem = vat_elem.nativeElement;
      //   if (vat_elem.value.startsWith('BE')) $(vat_elem).mask('AA 0000.000.0000');
      //   else $(vat_elem).unmask();
    }
  }
  public remarksBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(7,12,48)',
    textColor: 'white',
    fields: [
      {
        title: 'Représentant',
        field: 'merchant',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.merchantsData,
        listField: 'numberAndName',
      },
      { title: 'URL', type: 'url', field: 'url' },
      { title: 'Remarques', field: 'remarks', type: 'textarea' },
      {
        title: 'Avertissement',
        field: 'warning',
        type: 'textarea',
        comment: 'Affiche un avertissement lors de la sélection de ce client dans un dossier commercial',
      },
    ],
  };

  updateAddressBlockCity(component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event) {
    let addr: Address = component.data as Address;
    let postcodes: Postcode[] = addr.postcode
      ? this.postcodes.filter((value: Postcode, index: number, obj: Postcode[]) => {
          return value.postcode == addr.postcode;
        })
      : this.postcodes;
    block.fields[4].listItems.items = postcodes;
    addr.city = null;
  }
  updateAddressBlockPostcode(component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event) {
    let addr: Address = component.data as Address;
    let postcode: Postcode = this.postcodes.find((value: Postcode, index: number, obj: Postcode[]) => {
      return value.city == event;
    });
    if (postcode != null) addr.postcode = postcode.postcode;
  }

  private lastFocusAddr: Address = null;
  private addressCopy: Address = null;
  addrClick(component: InfoBlockComponent, block: InfoBlock, object: any, event: any) {
    this.lastFocusAddr = object as Address;
  }
  copyAddress(addr: Address) {
    if (addr) this.addressCopy = addr.clone(false);
  }
  pasteAddress(addr: Address) {
    if (addr && this.addressCopy) this.addressCopy.clone(false, addr);
  }

  contactBackColor(r, g, b, index, count) {
    if (count > 0) {
      let ratio = ((count - index - 1) / count) * 0.75;
      //console.log('ratio:', ratio);
      r = Math.round(r + (255 - r) * ratio);
      g = Math.round(g + (255 - g) * ratio);
      b = Math.round(b + (255 - b) * ratio);
    }
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }
  contactTextColor(index, count) {
    if (index >= count / 2 || count == 1) return 'white';
    else return 'black';
  }

  ngOnInit() {
    let self = this;
    Merchant.load().then(
      function (result: Merchant[]) {
        self.merchantsData.items = CredentialsService.isUserAllowed('customer_view_show_all_merchants')
          ? result
          : result.filter((value: Merchant, index, array) => {
              return value.user && value.user.id == CredentialsService.loggedUser.id;
            });
        if (!self.customerCopy.merchant)
          self.customerCopy.merchant = self.merchantsData.items.find(
            (m: Merchant) => m.id === CredentialsService.loggedMerchant.id
          );
      },
      function (err) {
        console.error('merchants loading error:', err);
      }
    );
    CustomerCategory.load().then(
      function (result: CustomerCategory[]) {
        self.categoriesData.items = result;
      },
      function (err) {
        console.error('categories loading error:', err);
      }
    );
    Lang.load().then(
      function (result: Lang[]) {
        self.langsData.items = result;
      },
      function (err) {
        console.error('langs loading error:', err);
      }
    );
    Deadline.load().then(
      function (result: Deadline[]) {
        self.deadlinesData.items = ArrayUtil.sortQuantityArray(result, 'name');
      },
      function (err) {
        console.error('deadlines loading error:', err);
      }
    );
    Country.load().then(
      function (result: Country[]) {
        self.countriesData.items = result;
      },
      function (err) {
        console.error('countries loading error:', err);
      }
    );
    Postcode.load().then(
      function (result: Postcode[]) {
        self.postcodes = result;
        self.ordinatorPostcodes.items = result;
        self.billingPostcodes.items = result;
        self.deliveryPostcodes.items = result;
        self.allPostcodes.items = result;
      },
      function (err) {
        console.error('postcodes loading error:', err);
      }
    );
    ServerApi.callModule('customers', 'nextNumbers').then(
      (result) => {
        this.nextNumbers.items = result.details;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.nameBlockComponent.setFocus('name');
      let iban_elem: any = this.bankBlockComponent.getInputElement('iban');
      //if (iban_elem) $(iban_elem.nativeElement).mask('AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA');
      this.formatVatNumber(this.bankBlockComponent, null, null, null, null);
    }, 100);
  }

  initView(data, ...args) {
    this.customer = data;
    this.customerCopy = this.customer.clone(true);
    console.log('init view with customer:', this.customerCopy);
    if (this.isCreatingDemand) this.toolbar.viewTitle = 'Demande de création de compte';
    else this.toolbar.viewTitle = 'Fiche client';
    Toolbar.getToolbarItem(this.toolbar, 'validateButton').visible =
      this.isCustomerWaitingValidation && this.writeAccess;
    Toolbar.getToolbarItem(this.toolbar, 'saveButton').disabled = !this.isCreatingDemand && !this.writeAccess;
    Toolbar.getToolbarItem(this.toolbar, 'copyAddress').disabled = !this.isCreatingDemand && !this.writeAccess;
    Toolbar.getToolbarItem(this.toolbar, 'pasteAddress').disabled = !this.isCreatingDemand && !this.writeAccess;
    Customer.load(
      null,
      ['name'],
      null,
      false,
      'id_parent IS NULL' + (this.customer.id ? ' AND id != ' + this.customer.id : '')
    ).then(
      (result: Merchant[]) => {
        this.parentCustomersData.items = result;
      },
      (err) => {
        console.error('parent customers loading error:', err);
      }
    );

    if (!(data instanceof Customer) || !data.id) {
      this.turnovers = [];
      this.incidents = [];
    } else {
      ServerApi.callModule('invoices', 'customerTotals', {
        id_customer: data.id,
      }).then(
        (result) => {
          this.turnovers = Array.isArray(result.details) ? result.details : [];
        },
        (err) => {
          this.turnovers = [];
        }
      );
      ServerApi.callModule('customers', 'getIncidents', {
        id_customer: data.id,
      }).then(
        (result) => {
          Incident.load(result.details).then(
            (result2) => {
              console.log('incidents:', result2);
              this.incidents = result2;
            },
            (err2) => {
              console.error(err2);
            }
          );
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  validateForm() {
    return LoadingPromise.create<any>((resolve, reject) => {
      if (this.customerCopy.iban) {
        if (!BusinessUtil.checkIban(this.customerCopy.iban)) {
          NotificationsComponent.push({
            type: 'warning',
            title: 'IBAN invalide',
            summary: 'Le code IBAN semble invalide. Veuillez le vérifier.',
          });
          return reject('iban incorrect');
        }
      }
      let form: Form = {
        fields: [
          {
            name: 'Nom du client',
            type: 'string',
            data: this.customerCopy,
            field: 'name',
            pattern: new RegExp(/.+/),
            element: this.nameBlockComponent.getElement('name'),
          },
          {
            name: 'Langue',
            type: 'foreign-list',
            data: this.customerCopy,
            field: 'lang',
            pattern: null,
            element: this.generalBlockComponent.getElement('lang'),
          },
          {
            name: 'Conditions de paiement',
            type: 'foreign-list',
            data: this.customerCopy,
            field: 'deadline',
            pattern: null,
            element: this.generalBlockComponent.getElement('deadline'),
          },
          {
            name: 'E-mail ordonateur',
            type: 'foreign-list',
            data: this.customerCopy.ordinator_address,
            field: 'email',
            pattern: /.+/g,
            element: this.ordinatorBlockComponent.getElement('email'),
          },
          {
            name: 'E-mail compta',
            type: 'foreign-list',
            data: this.customerCopy.billing_address,
            field: 'accounting_email',
            pattern: /.+/g,
            element: this.billingBlockComponent.getElement('accounting_email'),
          },
          {
            name: 'E-mail facturation',
            type: 'foreign-list',
            data: this.customerCopy.billing_address,
            field: 'invoicing_email',
            pattern: /.+/g,
            element: this.billingBlockComponent.getElement('invoicing_email'),
          },
          {
            name: 'Représentant',
            type: 'foreign-list',
            data: this.customerCopy,
            field: 'merchant',
            pattern: null,
            element: this.remarksBlockComponent.getElement('merchant'),
          },
        ],
      };
      if (this.customerCopy.valid)
        form.fields.push({
          name: 'Numéro de client',
          type: 'string',
          data: this.customerCopy,
          field: 'number',
          pattern: new RegExp(/^[A-Z]+[0-9]{4}$/),
          element: this.generalBlockComponent.getElement('number'),
        });
      for (let i = 0; i < this.customerCopy.invoicing_addresses.length; ++i) {
        form.fields.push({
          name: 'E-mail compta (' + (i + 2) + ')',
          type: 'foreign-list',
          data: this.customerCopy.invoicing_addresses[i],
          field: 'accounting_email',
          pattern: /.+/g,
          element: this.invoicingAddressesBlockComponent.toArray()[i].getElement('accounting_email'),
        });
        form.fields.push({
          name: 'E-mail facturation (' + (i + 2) + ')',
          type: 'foreign-list',
          data: this.customerCopy.invoicing_addresses[i],
          field: 'invoicing_email',
          pattern: /.+/g,
          element: this.invoicingAddressesBlockComponent.toArray()[i].getElement('invoicing_email'),
        });
      }
      let promises: Promise<any>[] = [];
      if (this.customerCopy.number != this.customer.number || !this.customerCopy.id) {
        promises.push(Customer.load(null, null, null, false, 'number="' + this.customerCopy.number + '"'));
      }
      Promise.all(promises).then(
        (result) => {
          console.log('customer search result:', result);
          if (promises.length > 0 && Array.isArray(result[0]) && result[0].length > 0) {
            NotificationsComponent.push({
              type: 'error',
              title: 'Numéro existant',
              summary:
                'Un client avec le numéro <b>' + this.customerCopy.number + '</b> existe déjà dans la base de données.',
            });
            reject('customer already exists with this number');
          } else {
            let result = FormValidator.validateForm(form);
            FormValidator.showFormInvalidFields(form, result);
            if (result !== true) {
              FormValidator.showNotification(result);
              reject(result);
            } else resolve(true);
          }
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur de vérification',
            summary: 'Impossible de vérifier la base de données pour le numéro de client.',
          });
          reject('failed to check customer number');
        }
      );
    });
  }

  save() {
    return new Promise<any>((resolve, reject) => {
      this.validateForm().then(
        (result) => {
          if (result === true) {
            this.customerCopy.clone(true, this.customer);
            this.customer.save2().then(
              (result) => {
                resolve(result);
                this.initView(this.customer);
              },
              (err) => {
                reject(err);
              }
            );
          } else console.error(result);
        },
        (err) => {
          console.error(err);
        }
      );
    });
  }

  duplicate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      this.customerCopy = this.customerCopy.clone(false);
      this.customerCopy.number = null;
      this.customerCopy.vat_number = null;
      this.customerCopy.bob_id = null;
      this.initView(this.customerCopy);
      resolve();
    }).then(
      (result) =>
        NotificationsComponent.push({
          type: 'success',
          title: 'Client dupliqué',
          summary: 'Le client a été dupliqué, mais pas encore enregistré.',
        }),
      (err) =>
        NotificationsComponent.push({
          type: 'error',
          title: 'Erreur',
          summary: "Une erreur s'est produite lors de la duplication.",
        })
    );
  }

  public contactsColumns = [
    textColumn('name1', { title: 'Contact', width: 200, readonly: false }),
    textColumn('name2', { title: 'Service', width: 200, readonly: false }),
    phoneColumn('phone', {
      title: 'Téléphone',
      width: 150,
      readonly: false,
      change: this.phoneChange,
    }),
    phoneColumn('mobile', {
      title: 'GSM',
      width: 150,
      readonly: false,
      change: this.phoneChange,
    }),
    emailColumn('email', { title: 'E-mail', width: 200, readonly: false }),
    textColumn('remarks', { title: 'Remarques', width: 300, readonly: false }),
  ];

  @ViewChild('contactsGrid') contactsGrid: DataGrid2Component;

  public selectedContacts: Address[] = [];
  public selectedDeliveryAddresses: Address[] = [];
  public selectedInvoicingAddresses: Address[] = [];

  public deliveryAddressesSelectionChanged() {
    this.selectedDeliveryAddresses.splice(0);
    for (let i = 0; i < this.customerCopy.delivery_addresses.length; ++i) {
      let addr = this.customerCopy.delivery_addresses[i];
      if (addr.selected === true) this.selectedDeliveryAddresses.push(addr);
    }
    // Toolbar.getToolbarItem(this.toolbar, 'deleteAddresses').disabled = (this.selectedDeliveryAddresses.length == 0);
  }
  public invoicingAddressesSelectionChanged() {
    this.selectedInvoicingAddresses.splice(0);
    for (let i = 0; i < this.customerCopy.invoicing_addresses.length; ++i) {
      let addr = this.customerCopy.invoicing_addresses[i];
      if (addr.selected === true) this.selectedInvoicingAddresses.push(addr);
    }
    // Toolbar.getToolbarItem(this.toolbar, 'deleteAddresses').disabled = (this.selectedInvoicingAddresses.length == 0);
  }

  public addContact() {
    this.customerCopy.contacts = [...this.customerCopy.contacts, new Address()];
  }
  public addDeliveryAddress() {
    this.customerCopy.delivery_addresses.unshift(new Address());
  }
  public addInvoicingAddress() {
    this.customerCopy.invoicing_addresses.unshift(new Address());
  }

  deleteContacts() {
    // ArrayUtil.removeElements(this.customerCopy.contacts, this.selectedContacts);
    ArrayUtil.removeElements(this.customerCopy.contacts, this.contactsGrid.selectedItems);
    this.customerCopy.contacts = [...this.customerCopy.contacts];
    this.contactsGrid.clearSelection();
  }
  deleteDeliveryAddresses() {
    ArrayUtil.removeElements(this.customerCopy.delivery_addresses, this.selectedDeliveryAddresses);
  }
  deleteInvoicingAddresses() {
    ArrayUtil.removeElements(this.customerCopy.invoicing_addresses, this.selectedInvoicingAddresses);
  }

  get getCustomer() {
    return this.customer;
  }
  public get isCreatingDemand() {
    return this.customerCopy && !this.customerCopy.id && this.customerCopy.valid != true;
  }
  public get isViewReadOnly() {
    return this.writeAccess === false && !this.isCreatingDemand;
  }
  public get isCustomerWaitingValidation() {
    return this.customerCopy && this.customerCopy.id && this.customerCopy.valid != true;
  }
  public validate() {
    this.customerCopy.valid = true;
    this.validateForm().then(
      (result) => {
        if (result === true) this.customerCopy.save2();
        else {
          this.customerCopy.valid = false;
          console.error(result);
        }
      },
      (err) => {
        this.customerCopy.valid = false;
        console.error(err);
      }
    );
  }

  public resetPassword() {
    if (this.customer.id) {
      DialogsComponent.displayComponent(ResetPasswordModalComponent, {
        newPassword: '',
      }).then((result) => {
        if (result !== DialogButton.RESULT_CANCEL) {
          ApiService.callModule('customers', 'resetPassword', {
            id_customer: this.customerCopy.id,
            password: result,
          }).then(
            (result) => {
              NotificationsComponent.push({
                type: 'success',
                title: 'Mot de passe modifé',
                summary: 'Le mot de passe a été modifié avec succès.',
              });
            },
            (err) => {
              NotificationsComponent.push({
                type: 'error',
                title: 'Erreur',
                summary: "Le mot de passe n'a pas pu être modifié.",
              });
              console.error(err);
            }
          );
        }
      });
    } else {
      NotificationsComponent.push({
        type: 'warning',
        title: 'Client non sauvegardé',
        summary: 'Veuillez sauvegarder le client pour pouvoir lui assigner un mot de passe.',
      });
    }
  }

  private _iframeLoadEvent: boolean = false;
  public onIframeLoaded = () => {
    if (this._iframeLoadEvent) {
      console.log('on iframe loaded!');
      this._iframeLoadEvent = false;
      $(this.loginForm.nativeElement).submit();
    }
  };
  public openStockPlatform() {
    console.log('open stock platform');
    this._iframeLoadEvent = true;
    this.showStockPlatform = true;
  }
  public closeStockPlatform() {
    this.showStockPlatform = false;
  }

  public shouldShowCopyToSales: boolean = false;
  public salesToCopy = { items: [] };
  public selectedCopyInvoicingAddress: number = -1;
  public selectedCopyDeliveryAddress: number = -1;
  public shouldCopyDeadline: boolean = false;
  public shouldCopyOrdinatorAddress: boolean = false;
  public shouldCopyInvoicingAddress: boolean = false;
  public shouldCopyDeliveryAddress: boolean = false;

  public salesToCopyColumns = [
    textColumn('numberWithVersion', { title: 'Numéro', width: 230 }),
    dateColumn('date', { title: 'Date', width: 90 }),
    textColumn('articleDesignation', { title: 'Désignation', width: 500 }),
  ];

  public showCopyToSales() {
    LoadingPromise.create((resolve, reject) => {
      if (!this.customerCopy.id) reject("Veuillez d'abord enregistrer le client");
      else
        Sale.load(null, ['~date'], null, false, `id_customer='${this.customerCopy.id}'`).then((sales) => {
          console.log('sales for this customer:', sales);
          resolve(sales);
        });
    })
      .then((sales: Sale[]) => {
        this.salesToCopy.items = sales;
        this.selectedCopyInvoicingAddress = -1;
        this.selectedCopyDeliveryAddress = -1;
        this.shouldShowCopyToSales = true;
        $(this.elRef.nativeElement).scrollTop(0);
      })
      .catch((err) => {
        NotificationsComponent.push({
          type: 'error',
          title: 'Erreur',
          summary: err,
        });
      });
  }

  @ViewChild('salesToCopyGrid') salesToCopyGrid: DataGrid2Component;

  public copyToSales() {
    const selectedSales = this.salesToCopyGrid.selectedItems;
    if (
      !this.shouldCopyDeadline &&
      !this.shouldCopyOrdinatorAddress &&
      !this.shouldCopyInvoicingAddress &&
      !this.shouldCopyDeliveryAddress
    ) {
      DialogsComponent.display({
        title: 'Informations manquantes',
        message: `
                    Veuillez d'abord sélectionner une ou plusieurs informations à copier.
                `,
        buttons: DialogButton.okOnlyButtons,
        icon: 'info',
      });
    } else if (!selectedSales || !selectedSales.length) {
      DialogsComponent.display({
        title: 'Informations manquantes',
        message: `
                    Veuillez d'abord sélectionner un ou plusieurs dossiers commerciaux.
                `,
        buttons: DialogButton.okOnlyButtons,
        icon: 'info',
      });
    } else this.callCopyToSalesApi();
  }

  private callCopyToSalesApi() {
    DialogsComponent.display({
      title: 'Confirmation',
      message: `
                Ceci mettra à jour les informations sélectionnées pour les dossiers commerciaux sélectionnés.<br/>
                <strong style="color: red;">Cette opération est irréversible !</strong><br/><br/>
                Êtes-vous sûr(e) de vouloir continuer ?
            `,
      buttons: DialogButton.yesNoButtons,
      icon: 'warning',
    }).then((result) => {
      if (result == DialogButton.RESULT_YES) {
        const deadline: string = this.customerCopy.deadline;
        const ordinatorAddress: Address = this.customerCopy.ordinator_address;
        const deliveryAddress: Address =
          this.selectedCopyDeliveryAddress < 0
            ? this.customerCopy.delivery_address
            : this.customerCopy.delivery_addresses[this.selectedCopyDeliveryAddress];
        const invoicingAddress: Address =
          this.selectedCopyInvoicingAddress < 0
            ? this.customerCopy.billing_address
            : this.customerCopy.invoicing_addresses[this.selectedCopyInvoicingAddress];
        ApiService.callModule('sales', 'updateSalesForCustomer', {
          id_customer: this.customerCopy.id,
          deadline: this.shouldCopyDeadline ? deadline : null,
          ordinator_contact: this.shouldCopyOrdinatorAddress ? ordinatorAddress.contactName : null,
          ordinator_address: this.shouldCopyOrdinatorAddress ? ordinatorAddress.getPostalAddress() : null,
          ordinator_emails: this.shouldCopyOrdinatorAddress ? ordinatorAddress.email : null,
          invoicing_contact: this.shouldCopyInvoicingAddress ? invoicingAddress.contactName : null,
          invoicing_address: this.shouldCopyInvoicingAddress ? invoicingAddress.getPostalAddress() : null,
          invoicing_emails: this.shouldCopyInvoicingAddress ? invoicingAddress.invoicing_email : null,
          reminders_emails: this.shouldCopyInvoicingAddress ? invoicingAddress.accounting_email : null,
          delivery_contact: this.shouldCopyDeliveryAddress ? deliveryAddress.contactName : null,
          delivery_address: this.shouldCopyDeliveryAddress ? deliveryAddress.getPostalAddress() : null,
          sales_ids: this.salesToCopyGrid.selectedItems.map((sale: Sale) => sale.id),
        }).then(
          (data) => {
            NotificationsComponent.push({
              type: 'success',
              title: 'Opération réussie',
              summary: 'Les dossiers commerciaux ont bien été mis à jour.',
            });
            this.closeCopyToSales();
          },
          (err) => {
            NotificationsComponent.push({
              type: 'error',
              title: 'Echec',
              summary: 'La mise à jour a échoué.',
            });
            console.error('CopyToSales error:', err);
          }
        );
      }
    });
  }

  public closeCopyToSales() {
    this.shouldShowCopyToSales = false;
  }
}
