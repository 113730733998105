<div class="toolbar {{ toolbar.class }}" *ngIf="toolbar">
  <button class="btn btn-default" (click)="prevPage()">
    <i class="fa fa-chevron-left"></i>
    <span>Retour</span>
  </button>
  <div class="toolbar-separator"></div>
  <div
    *ngFor="let element of toolbar.elements"
    [ngClass]="{
      'toolbar-separator': element.type == 'separator',
      'toolbar-separator-large': element.type == 'separator-large'
    }"
  >
    <select
      *ngIf="element.type == 'select' && !(element.visible === false)"
      class="form-control"
      [(ngModel)]="element.value"
    >
      <option *ngFor="let option of element.options" [value]="option.value">
        {{ option.text }}
      </option>
    </select>
    <button
      *ngIf="element.type == 'button' && !(element.visible === false)"
      class="btn btn-default form-control {{ element.className }}"
      (click)="element.click(toolbar.data)"
      [disabled]="element.disabled || element.access === false"
    >
      <i class="fa fa-{{ element.icon }}"></i
      ><span [innerHTML]="element.text"></span>
    </button>
    <label
      *ngIf="element.type == 'toggle-button' && !(element.visible === false)"
      class="btn btn-default"
      [(ngModel)]="element.value"
      (click)="element.click(toolbar.data)"
      btnCheckbox
    >
      <i class="fa fa-{{ element.icon }}"></i
      ><span [innerHTML]="element.text"></span>
    </label>
    <img
      *ngIf="element.type == 'image' && !(element.visible === false)"
      [src]="element.src"
      alt=""
    />
  </div>
  <div class="toolbar-separator"></div>
  <div style="flex: 1" *ngIf="isDesktop" #interopToolbar></div>
  <h3 class="ml-auto">{{ toolbar.viewTitle }}</h3>
  <div class="account-menu">
    <div *ngIf="isMobile" #interopMobileToolbar></div>
    <div
      class="account-menu-icon"
      (click)="toolbar.showAccountMenu = !toolbar.showAccountMenu"
    >
      <i class="fa fa-bars"></i>
    </div>
    <div
      class="account-menu-overlay"
      *ngIf="toolbar.showAccountMenu"
      (click)="toolbar.showAccountMenu = false"
    ></div>

    <ul
      class="toolbar-menu-content"
      [ngStyle]="{
        height: toolbar.showAccountMenu ? 'auto' : '0%',
        display: toolbar.showAccountMenu ? 'block' : 'none'
      }"
    >
      <div style="flex: 1" *ngIf="isMobile" #interopToolbar></div>
      <li *ngIf="!isDesktop" class="separator"></li>

      <li
        *ngFor="let item of accountingsItems"
        class="toolbar-menu-item"
        (click)="accountingsItemClick($event, item)"
      >
        <i class="fa fa-calculator"></i
        ><span>Comptabilité {{ item.fullDescription }}</span>
      </li>

      <li class="separator"></li>
      <li class="toolbar-menu-item" (click)="reload()">
        <i class="fas fa-sync-alt"></i><span>Recharger l'ERP</span>
      </li>
      <li class="toolbar-menu-item" (click)="logout()">
        <i class="fas fa-sign-out-alt"></i><span>Se déconnecter</span>
      </li>
    </ul>
  </div>
</div>
