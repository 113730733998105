import { Component, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
import { config } from '../../../../classes/config';
import { waitForInteropNavigate } from '../../../interop/helpers';
import { windowWithInterop } from '../../../interop/types';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage-view.component.html',
  styleUrls: ['./homepage-view.component.scss'],
})
export class HomepageViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big toolbar-transparent toolbar-nocontent',
    viewTitle: 'Table des matières',
    data: this,
    elements: [{ type: 'image', src: 'assets/img/logo_white_shadow.png' }, { type: 'spacing' }],
  };
  public _routeId = '';
  public logoAlt = config.companyName;

  @ViewChild('mydiv') mydiv;

  static registredGroups = {};
  homepageGroups = null;

  public static registerGroups(groups) {
    for (const groupKey in groups) {
      if (groups.hasOwnProperty(groupKey)) {
        const group = groups[groupKey];
        if (!HomepageViewComponent.registredGroups[groupKey]) {
          HomepageViewComponent.registredGroups[groupKey] = group;
        } else if (Array.isArray(group.children)) {
          if (!HomepageViewComponent.registredGroups[groupKey].children) {
            HomepageViewComponent.registredGroups[groupKey].children = [];
          }
          group.children.forEach((child) => {
            HomepageViewComponent.registredGroups[groupKey].children.push(child);
          });
        }
      }
    }
  }

  ngOnInit() {
    var arr = [];
    var groups = HomepageViewComponent.registredGroups;
    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        const element = groups[key];
        arr.push(element);
      }
    }
    this.homepageGroups = arr;
  }

  openView(child) {
    if (child.href) {
      waitForInteropNavigate().then(() => {
        return windowWithInterop.reactInterop.navigate(child.href);
      });
    } else {
      let component = child.component;
      let args = child.getArgs && typeof child.getArgs === 'function' ? child.getArgs() : [];
      ViewsComponent.openView(component, ...args);
    }
  }

  private _filteredHomepageGroups: any = null;
  public get filteredHomepageGroups() {
    if (!this._filteredHomepageGroups) {
      let groups: any[] = [];
      for (let groupName in this.homepageGroups) {
        let group = this.homepageGroups[groupName];
        if (!group.permission || CredentialsService.isUserAllowed(group.permission)) {
          let _group = {
            name: group.name,
            backColor: group.backColor,
            textColor: group.textColor,
            height: group.height,
            fontSize: group.fontSize,
            lineHeight: group.lineHeight,
            children: [],
          };
          for (let i = 0; i < group.children.length; ++i) {
            let child = group.children[i];
            if (!child.permission || CredentialsService.isUserAllowed(child.permission)) {
              _group.children.push(child);
            }
          }
          if (_group.children.length > 0) groups.push(_group);
        }
      }
      this._filteredHomepageGroups = groups;
    }
    return this._filteredHomepageGroups;
  }
}
