import { Supplier } from './classes/Supplier.class';

const createSupplier = (number: string = null) => {
  const supplier = new Supplier();
  supplier.name = 'Nouveau fournisseur';
  supplier.number = number;
  return supplier;
};

export const createSupplierWithNewNumber = async () =>
  Supplier.getNextNumber().then(
    function (result) {
      console.log('supplier next number:', result);
      return createSupplier('FP' + result.details);
    },
    function (err) {
      console.error('get customer number error:', err);
      return createSupplier();
    }
  );
