import { Customer } from './classes/Customer.class';

export const createCustomerWithNewNumber = async () => {
  let self = this;
  return await Customer.getNextNumber().then(
    function (result) {
      console.log('getNextNumber result:', result);
      return createCustomer('BEL' + result.details);
    },
    function (err) {
      console.error('get customer number error:', err);
      return createCustomer();
    }
  );
};

export const createCustomer = (number: string = null) => {
  const customer = new Customer();
  customer.name = 'Nouveau Client';
  customer.number = number;
  return customer;
};
