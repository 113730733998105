import { Config } from './types';

export const config: Config = {
  db_version: 4,
  apiUrl: 'http://graphiseal.local/api',
  baseUrl: 'http://graphiseal.local/',
  companyName: 'GraphiSeal',
  mailBcc: ['ct@graphiseal.com'],
  orderMailBcc: ['ct@graphiseal.com'],
  orderMail: 'stock@graphiseal.com',
  tcPdfPath: '%DROPBOX%\\Gestion de graphiseal\\CONDITIONS GÉNÉRALES ACHATS.pdf',
  interop: {
    enabled: false,
    modules: {
      articles: { list: false, details: false },
      customers: { list: false, details: false },
      devices: false,
      incidents: { list: false, details: false },
      invoices: { list: false, details: false },
      nomenclatures: false,
      orders: { list: false, details: false },
      ordersFollowUp: { list: false, details: false },
      otherData: false,
      payments: false,
      reimbursements: { list: false, details: false },
      sales: { list: false, details: false },
      stocks: { list: false, details: false },
      stockAdjustments: { list: false, details: false },
      stockCalls: { list: false, details: false },
      stockFollowUp: false,
      suppliers: { list: false, details: false },
      wages: false,
    },
  },
};

export const initConfig = async () => {
  try {
    const response = await fetch('assets/config.json');
    const partialConfig = await response.json();
    console.log('Config loaded from file:', partialConfig);
    Object.assign(config, partialConfig);
  } catch (err) {
    console.error('Error while loading config:', err);
  }

  // Check if URLs ends with '/'
  if (!config.apiUrl.endsWith('/')) config.apiUrl += '/';
  if (!config.baseUrl.endsWith('/')) config.baseUrl += '/';

  console.log('Final config:', config);
  return config;
};
