import { mapValues } from 'lodash';

/**
 * Execute one by one an array of promises, returning the array of results
 */
export const chainPromises = async (promisesFns: (() => Promise<any>)[]): Promise<any[]> => {
  let finalPromise = Promise.resolve();
  const results: any = [];
  promisesFns.forEach((promiseFn) => {
    finalPromise = finalPromise.then(() =>
      promiseFn().then((result) => {
        results.push(result);
      })
    );
  });
  await finalPromise;
  return results;
};

/**
 * Execute promises stored in a record, returning the record of results
 */
export const chainPromisesObject = async <K extends string>(
  promisesFns: Record<K, () => Promise<any>>
): Promise<Record<K, any>> => {
  let finalPromise = Promise.resolve();
  const results: Record<K, any> = mapValues(promisesFns, () => undefined) as Record<K, any>;
  Object.entries(promisesFns).forEach(([key, promiseFn]: [K, () => Promise<any>]) => {
    finalPromise = finalPromise.then(promiseFn).then((result) => {
      results[key] = result;
    });
  });
  await finalPromise;
  return results;
};
